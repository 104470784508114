import React from "react"
import { getLanguage } from "Utils/Language"
import { navigate } from "gatsby"

import "./BlogPostCardCategories.style.scss"

const BlogPostCardCategories = ({
  activePageCategory,
  post_categories = [],
}) => {
  const handleCategoryClick = (e, categoryName) => {
    const { url } = getLanguage()
    const { change } = activePageCategory
    const pathName = `${url}/blog/`

    e.preventDefault()
    change({ name: categoryName })

    if (window.location.pathname === pathName) window.scrollTo({ top: 0 })
    else navigate(pathName)
  }

  return (
    <div className="BlogPostCardCategories">
      {post_categories.map(({ category_name }, index) => (
        <button
          key={index}
          className="NoButtonStyle BlogPostCardCategory"
          onClick={e => handleCategoryClick(e, category_name)}
        >
          {category_name}
        </button>
      ))}
    </div>
  )
}

export default BlogPostCardCategories
