import React, { useState, useEffect, memo } from "react"

const InputField = ({
  placeholder = "",
  onSubmit = () => {},
  value: propValue = "",
}) => {
  const [value, setValue] = useState("")

  useEffect(() => {
    setValue(propValue)
  }, [propValue])

  const handleChange = e => setValue(e.target.value)

  const handleKeyDown = e => {
    if (e.keyCode === 13) onSubmit(value)
  }

  return (
    <input
      className="InputField"
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  )
}

export default memo(InputField)
