import React, { useState, useEffect } from "react"

const TwoPassRendering = ({ children }) => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const key = isClient ? "client" : "server"

  return <React.Fragment key={key}>{children}</React.Fragment>
}

export default TwoPassRendering
