import React, { useState } from "react"
import Link from "Components/Link"
import { getMenuLink } from "Utils/Link"
import { decode } from "html-entities"

function MenuItem({ item: { children, title } }) {
  const [expanded, setExpanded] = useState(false)

  const renderLink = (item, index) => {
    const { title, object_id, object_slug, type, url: menuUrl } = item
    const key = `${object_id}-${index}`

    const { url, isExternal } = getMenuLink({ type, object_slug, url: menuUrl })

    return (
      <Link to={url} isExternal={isExternal} key={key} className="MenuItem">
        {decode(title)}
      </Link>
    )
  }

  return (
    <div
      className={`MenuColumn ${expanded ? "isExpanded" : ""}`}
      onClick={() => setExpanded(!expanded)}
      aria-hidden="true"
      role="button"
    >
      <span className="MenuColumnTitle">{decode(title)}</span>
      {children && children.map(renderLink)}
    </div>
  )
}

export default MenuItem
