import React from "react"
import { useSharedContext } from "Src/contexts/SharedContext"
import BlogPostCategories from "./BlogPostCategories.component"

const BlogPostCategoriesContainer = props => {
  const { activePageCategory } = useSharedContext()

  return (
    <BlogPostCategories {...props} activePageCategory={activePageCategory} />
  )
}

export default BlogPostCategoriesContainer
