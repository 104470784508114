// PhoneNumberInput.jsx
import React from "react"
import InputMask from "react-input-mask"

const InputMasking = ({ value, onChange, placeholder, key, type, onBlur, onFocus, className }) => {
  return (
    <InputMask
      className={className} 
      mask="(999) 999-9999" // Define your desired phone number mask
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      onBlur={onBlur}
      key={key}
      onFocus={onFocus}
    >
      {inputProps => <input {...inputProps} type={type} />}
    </InputMask>
  )
}

export default InputMasking
