import React, { useState, useEffect, useRef } from "react"
import { withTranslation } from "react-i18next"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonNext,
  ButtonBack,
} from "pure-react-carousel"
import ActiveSlide from "Components/ActiveSlide"
import { htmlToReact } from "Utils/HtmlParser"
import "./ColorSelectorTool.style.scss"
import ProgressiveImage from "Components/ProgressiveImg"
import { getPreviewImageSrc } from "Utils/Helpers"

export const MODEL_COUNT_SLIDE = 9

const ColorSelectorTool = ({
  microcopy = "",
  blockTitle = "",
  subTitle = "",
  text = "",
  modelTitle = "",
  modelMap = [{}],
  modelSlideMap = [{}],
  backgroundColor = "",

  t,
}) => {
  const [activeModelId, setActiveModelId] = useState(0)
  const [activeOptionIndex, setActiveOptionIndex] = useState(0)
  const [currentModelSlideIndex, setCurrentModelSlideIndex] = useState(1)
  const [modelSlideHeight, setModelSlideHeight] = useState(0)

  const modelRef = useRef(null)

  useEffect(() => {
    setSlideHeight()

    const handleResize = () => {
      setSlideHeight()
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const setSlideHeight = () => {
    if (modelRef.current) {
      setModelSlideHeight(modelRef.current.clientHeight)
    }
  }

  const renderSingleModel = ({ id, title, imageUrl }) => {
    const isActive = activeModelId === id ? "isActive" : ""

    const handleClick = () => {
      setActiveModelId(id)
      setActiveOptionIndex(0)
    }

    return (
      <button
        key={id}
        className={`SingleModel NoButtonStyle ${isActive}`}
        onClick={handleClick}
      >
        <ProgressiveImage
          alt="Model"
          src={getPreviewImageSrc(imageUrl)}
          largeSrc={imageUrl}
          className="SingleModelImage"
        />
        <span className="SingleModelTitle">{title}</span>
      </button>
    )
  }

  const renderSlideSingleArrow = (isBack = false) => {
    const { length: modelSlideMapLength } = modelSlideMap
    const handleClick = () => {
      const getBackIndex = () =>
        currentModelSlideIndex > 1
          ? currentModelSlideIndex - 1
          : modelSlideMapLength
      const getNextIndex = () =>
        currentModelSlideIndex === modelSlideMapLength
          ? 1
          : currentModelSlideIndex + 1

      setCurrentModelSlideIndex(isBack ? getBackIndex() : getNextIndex())
    }

    if (modelSlideMapLength < 2) {
      return null
    }

    const Tag = isBack ? ButtonBack : ButtonNext

    return (
      <Tag
        className="SliderButton NoButtonStyle"
        onClick={() => handleClick(isBack)}
      />
    )
  }

  const renderSliderArrows = () => {
    const { length: modelSlideMapLength } = modelSlideMap

    return (
      <div className="SliderButtonWrapper">
        {renderSlideSingleArrow(true)}
        <span className="SliderCurrentSlide">
          <span className="SliderCurrentSlideIndex">
            {currentModelSlideIndex}
          </span>
          {` ${t("colorSelectorTool.of")} ${modelSlideMapLength}`}
        </span>
        {renderSlideSingleArrow()}
      </div>
    )
  }

  const renderModelSingleSlide = (modelMap, index) => {
    const style = { height: modelSlideHeight }
    const ref = index === 0 ? modelRef : null

    return (
      <Slide key={index} className="SliderSlide" style={style}>
        <div className="Models" ref={ref}>
          {modelMap.map(renderSingleModel)}
        </div>
      </Slide>
    )
  }

  const renderModelsSlider = () => {
    const { length: modelSlideMapLength } = modelSlideMap
    const isMultipleSlides = modelMap.length > MODEL_COUNT_SLIDE

    const handleOnSlideChange = currentModelSlideIndex => {
      setCurrentModelSlideIndex(currentModelSlideIndex + 1)
    }

    return (
      <CarouselProvider
        totalSlides={modelSlideMapLength}
        infinite
        dragEnabled={isMultipleSlides}
        touchEnabled={isMultipleSlides}
      >
        <ActiveSlide
          onSlideChange={handleOnSlideChange}
          currentSlide={currentModelSlideIndex - 1}
        >
          <Slider className="Slider">
            {modelSlideMap.map(renderModelSingleSlide)}
          </Slider>
        </ActiveSlide>
        {isMultipleSlides && renderSliderArrows()}
      </CarouselProvider>
    )
  }

  const renderModels = () => {
    return (
      <div className="ModelsWrapper">
        <span className="ModelsTitle">{htmlToReact(modelTitle)}</span>
        {renderModelsSlider()}
      </div>
    )
  }

  const renderSelectedOption = activeModelMap => {
    const {
      optionMap: {
        [activeOptionIndex]: {
          imageUrl,
          titleGreen = "",
          titleBlack = "",
          textGreen = "",
          textBlack = "",
        },
      },
    } = activeModelMap

    return (
      <div className="SelectedOption">
        <ProgressiveImage
          alt="Option"
          src={getPreviewImageSrc(imageUrl)}
          largeSrc={imageUrl}
          className="SelectedOptionImage"
        />
        <p className="SelectedOptionText">
          {titleGreen && (
            <span className="SelectedOptionTextFirst">{titleGreen}</span>
          )}
          {titleBlack}
        </p>
        <p className="SelectedOptionText">
          {textGreen && (
            <span className="SelectedOptionTextFirst">{textGreen}</span>
          )}
          {textBlack}
        </p>
      </div>
    )
  }

  const renderSingleOption = ({ id, bottomTitle, imageUrl }, index) => {
    const isActive = activeOptionIndex === index ? "isActive" : ""

    return (
      <button
        key={id}
        className="SingleOption NoButtonStyle"
        onClick={() => {
          setActiveOptionIndex(index)
        }}
      >
        <div className={`SingleOptionImageWrapper ${isActive}`}>
          <ProgressiveImage
            alt="Option"
            src={getPreviewImageSrc(imageUrl)}
            largeSrc={imageUrl}
            className="SingleOptionImage"
          />
        </div>
        <span className="SingleOptionTitle">{bottomTitle}</span>
      </button>
    )
  }

  const renderOptions = activeModelMap => {
    const { optionMap } = activeModelMap

    return <div className="Options">{optionMap.map(renderSingleOption)}</div>
  }

  return (
    <div className="ColorSelectorTool" style={{ backgroundColor }}>
      <div className="ContentWrapper">
        <div className="Section">
          <p className="Microcopy">{htmlToReact(microcopy)}</p>
          <h2 className="BlockTitle">{htmlToReact(blockTitle)}</h2>
          <h3 className="SubTitle">{htmlToReact(subTitle)}</h3>
          <p className="Text">{htmlToReact(text)}</p>
          {renderModels()}
        </div>
        <div className="Section">
          {renderSelectedOption(
            modelMap.find(({ id }) => id === activeModelId),
          )}
          {renderOptions(modelMap.find(({ id }) => id === activeModelId))}
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(ColorSelectorTool)
