import React, { useState, useEffect, useRef } from "react"
import "./FAQSection.style.scss"
import { withTranslation } from "react-i18next"
import { getStyleObjectFromString } from "Utils/Helpers"
import HomepageFAQATab from "./FAQTab.component"
import SearchIcon from "../../assets/Search.svg"

function FAQSection({ node, t }) {
  const [search, setSearch] = useState("")
  const [selectedTags, setSelectedTags] = useState([])
  const [tagsToDisplay, setTagsToDisplay] = useState([])
  const [tagsList, setTagsList] = useState(null)
  const [urlTags, setUrlTags] = useState([])

  const searchInputRef = useRef(null)

  useEffect(() => {
    // Tags that were passed in the URL params
    const tags = new URLSearchParams(window.location.search).get("tags")
    const formattedTags = tags
      ? tags
          .replace(/\//g, "")
          .split(",")
          .map(tag => tag.toLowerCase())
      : []
    setUrlTags(formattedTags)
  }, [])

  useEffect(() => {
    if (tagsList !== null) return
    try {
      const parsedTagsList = JSON.parse(node.attribs.tagslist)
      setTagsList(parsedTagsList)
    } catch {
      setTagsList([])
    }
  }, [node.attribs.tagslist, tagsList])

  const getDataForFaqTab = children => {
    const titleData = children[0]
    const textData = children[1]
    const title =
      titleData && titleData.children ? titleData.children[0].data : ""
    const text = textData && textData.children ? textData.children[0].data : ""
    return { title, text }
  }

  const setTagstoDisplay = tagstoDisplay => {
    const unqiueTags = Array.from(new Set(tagstoDisplay))

    const arrayLengthIsIdentical = unqiueTags.length === tagsToDisplay.length
    const arraysAreIdentical = tagsToDisplay.every(
      (value, index) => value === unqiueTags[index],
    )

    if (arrayLengthIsIdentical && arraysAreIdentical) return

    setTagsToDisplay(unqiueTags)
  }

  const handleSearch = () => {
    const searchTerm = searchInputRef.current.value.toLowerCase()
    setSearch(searchTerm)
    setSelectedTags([])
    unsetUrlTags()
  }

  const handleTagClick = id => {
    if (id === null) {
      setSelectedTags([])
      unsetUrlTags()
      return
    }
    setSelectedTags(prevTags =>
      prevTags.includes(id)
        ? prevTags.filter(tagId => tagId !== id)
        : [...prevTags, id],
    )
    unsetUrlTags()
  }

  const unsetUrlTags = () => {
    if (urlTags.length === 0) return
    setUrlTags([])
  }

  const needToDisplayTab = tabData => {
    const {
      tagIds = [],
      keywords = [],
      text = "",
      title = "",
      isHidden = false,
    } = tabData

    if (isHidden) return false

    const activeTags = (tagsList || []).filter(({ id }) =>
      tagIds.some(tagId => tagId === id),
    )

    if (urlTags.length) {
      const doesTagsMatch = activeTags.some(({ name }) =>
        urlTags.includes(name.toLowerCase()),
      )
      const doesKeywordsMatch = keywords.some(keyword =>
        urlTags.includes(keyword.toLowerCase()),
      )
      return doesTagsMatch || doesKeywordsMatch
    }

    const doesTitleMatchSearch = title.toLowerCase().includes(search)
    const doesTextMatchSearch = text.toLowerCase().includes(search)
    const doesTagsMatchSearch = activeTags.some(
      ({ name }) => name.toLowerCase() === search,
    )
    const doesKeywordsMatchSearch = keywords.some(
      keyword => keyword.toLowerCase() === search,
    )
    const doesMatchSearch =
      doesTitleMatchSearch ||
      doesTextMatchSearch ||
      doesTagsMatchSearch ||
      doesKeywordsMatchSearch

    if (!selectedTags || !selectedTags.length) return doesMatchSearch

    const hasSelectedTags = tagIds.some(tagId => selectedTags.includes(tagId))
    return hasSelectedTags && doesMatchSearch
  }

  const renderSearhBar = (searchBar, index) => {
    const { children, attribs, name: TagName } = searchBar
    const childrenNodes = children.reduce((acc, child, index) => {
      const {
        name: Tag,
        attribs: { class: className, type },
      } = child

      if (className === "SearchField") {
        return [
          ...acc,
          <Tag
            key={index}
            className={className}
            ref={searchInputRef}
            type={type}
            placeholder={t("faqSection.searchFieldPlaceholder")}
          />,
          <SearchIcon key={`svg-${index}`} />,
        ]
      }

      if (className === "SearchButton") {
        return [
          ...acc,
          <Tag key={index} className={className} onClick={handleSearch}>
            {t("faqSection.searchFieldButton")}
          </Tag>,
        ]
      }

      return acc
    }, [])

    return (
      <TagName key={index} className={attribs.class}>
        {childrenNodes}
      </TagName>
    )
  }

  const renderTagsList = tagsList => {
    const { children } = tagsList

    const childrenNodes = children.reduce((acc, child, index) => {
      const {
        children: grandChildren,
        name: Tag,
        attribs: { class: className, id },
      } = child
      const numericId = Number(id)

      const [childrenData = {}] = grandChildren
      const { data = null } = childrenData
      const isActive =
        selectedTags.includes(numericId) || urlTags.includes(data.toLowerCase())

      return [
        ...acc,
        <Tag
          key={`tag-${index}`}
          className={`${className} ${isActive ? "isActive" : ""}`}
          onClick={() => handleTagClick(numericId)}
        >
          {data}
        </Tag>,
      ]
    }, [])

    return childrenNodes
  }

  const renderTagsBar = (tagsBar, index) => {
    const {
      children,
      attribs: { class: parentClassName },
      name: TagName,
    } = tagsBar
    const childrenNodes = children.reduce((acc, child, index) => {
      const {
        children: grandChildren,
        name: Tag,
        attribs: { class: className },
      } = child
      const [childrenData = {}] = grandChildren
      const { data } = childrenData

      if (className === "TagsWrapper") {
        return [...acc, ...renderTagsList(child)]
      }

      acc.push(
        <Tag className={className} key={index}>
          {data}
        </Tag>,
      )

      if (className === "Popular") {
        const isActive =
          !selectedTags.length && !urlTags.length ? "isActive" : ""
        acc.push(
          <button
            className={`NoButtonStyle Tag ${isActive}`}
            onClick={() => handleTagClick(null)}
          >
            {t("faqSection.allButton")}
          </button>,
        )
      }

      return acc
    }, [])

    if (childrenNodes && childrenNodes.length <= 2) return null

    return (
      <TagName key={index} className={parentClassName}>
        {childrenNodes}
      </TagName>
    )
  }

  const renderTopSection = topSection => {
    const {
      children,
      name: TagName,
      attribs: { class: parentClassName },
    } = topSection

    const childrenNodes = children.reduce((acc, child, index) => {
      const {
        attribs: { class: className },
        name: Tag,
        children: grandChildren,
      } = child
      const [childrenData] = grandChildren
      const { data } = childrenData

      if (className === "SearchBar") {
        return [...acc, renderSearhBar(child, index)]
      }

      if (className === "TagsBar") {
        return [...acc, renderTagsBar(child, index)]
      }

      const finalChild = (
        <Tag key={index} className={className}>
          {data}
        </Tag>
      )

      return [...acc, finalChild]
    }, [])

    return <TagName className={parentClassName}>{childrenNodes}</TagName>
  }

  const renderFaqTabs = (faqTabs, index) => {
    const {
      children,
      attribs: { class: parentClassName, style = "" },
      name: TagName,
    } = faqTabs
    const tagsToDisplay = []

    const childrenNodes = children.reduce((acc, child, index) => {
      const {
        children: grandChildren,
        attribs: { ishidden = "false", tagids = "", keywords = "" },
      } = child
      const { title, text } = getDataForFaqTab(grandChildren)
      if (!title) return acc

      const isHidden = ishidden !== "false"
      const tagIds = tagids
        .split(",")
        .filter(tag => tag !== "")
        .map(tag => Number(tag))
      const keywordList = keywords.split(",").filter(keyword => keyword !== "")

      const needToDisplayTabValue = needToDisplayTab({
        text,
        title,
        tagIds,
        keywords: keywordList,
        isHidden,
      })

      if (!grandChildren.length || !needToDisplayTabValue) return acc

      tagsToDisplay.push(...tagIds)

      const childElement = (
        <HomepageFAQATab key={index} childrenData={grandChildren} />
      )

      return [...acc, childElement]
    }, [])

    setTagstoDisplay(tagsToDisplay)

    return (
      <TagName
        className={parentClassName}
        style={getStyleObjectFromString(style)}
        key={index}
      >
        {childrenNodes && childrenNodes.length ? (
          childrenNodes
        ) : (
          <p>{t("faqSection.noResultFound")}</p>
        )}
      </TagName>
    )
  }

  return (
    <node.name className={node.attribs.class}>
      {renderTopSection(node.children[0])}
      {renderFaqTabs(node.children[1])}
    </node.name>
  )
}

export default withTranslation()(FAQSection)
