import React, { useState } from "react"
import { Slider, Slide } from "pure-react-carousel"
import BlockTransition from "Components/BlockTransition"
import { useSharedContext } from "Src/contexts/SharedContext"

import "pure-react-carousel/dist/react-carousel.es.css"
import "./ContentAndImageCarousel.style.scss"

const SlideWrapper = ({
  image = "",
  title = "",
  text = "",
  index,
  isActive,
  isOpacityLower,
  onSlideChange,
  elemsTransition,
}) => {
  const defaultPossition = { x: 0, y: 0 }
  const [{ x: downX, y: downY }, setMouseDownPossition] =
    useState(defaultPossition)
  const [{ x: upX, y: upY }, setMouseUpPossition] = useState(defaultPossition)

  if (downX && downY && upX && upY && (downX === upX || downY === upY)) {
    onSlideChange(index)
    setMouseDownPossition(defaultPossition)
    setMouseUpPossition(defaultPossition)
  }

  const handleImageClick = () => {
    onSlideChange(index)
  }

  return (
    <Slide
      className={`Slide ${isActive ? "isActive" : ""} ${
        isOpacityLower ? "isOpacityLower" : ""
      }`}
      key={index}
      onMouseDown={({ screenX, screenY }) =>
        setMouseDownPossition({ x: screenX, y: screenY })
      }
      onMouseUp={({ screenX, screenY }) =>
        setMouseUpPossition({ x: screenX, y: screenY })
      }
    >
      <BlockTransition elemsTransition={elemsTransition}>
        {image && (
          // eslint-disable-next-line
          <div className="ImageWrapper" onClick={handleImageClick}>
            {image}
          </div>
        )}
        {(title || text) && (
          <div className="TextWrapper">
            {title && title}
            {text && text}
          </div>
        )}
      </BlockTransition>
    </Slide>
  )
}

const ContentAndImageCarousel = ({
  currentSlide,
  childrenMap,
  onSlideChange,
  elemsTransition = [],
}) => {
  const { isMobile } = useSharedContext()

  const createSlide = ({ title, text, image }, index, isWithoutImage) => {
    if (isWithoutImage) {
      return (
        <div className="TextWrapper" key={index}>
          <div className="ActiveBlock">
            {title}
            {text}
          </div>
          <div className="Block">{title}</div>
        </div>
      )
    }

    const middleImageCountDesktop = 3
    const middleImageCount = isMobile ? 1 : middleImageCountDesktop

    return (
      <SlideWrapper
        image={image}
        title={title}
        text={text}
        index={index}
        isActive={index === currentSlide}
        isOpacityLower={
          index < currentSlide || index >= currentSlide + middleImageCount
        }
        onSlideChange={onSlideChange}
        key={index}
        elemsTransition={elemsTransition}
      />
    )
  }

  const createContent = isWithoutImage => {
    return childrenMap.map((child, index) =>
      createSlide(child, index, isWithoutImage),
    )
  }

  // Changing these values needs to be changed scss as well.
  // $image-width-mobile : $image-width;
  const maxWidth = isMobile ? "35vw" : "20vw"
  // $image-max-width
  const imageMaxWidth = "300px"
  const transformCalc = `calc((min(${imageMaxWidth}, ${maxWidth}) + 20px - 100%)`
  const transform = `translateX(${transformCalc} * -${currentSlide}))`

  return (
    <>
      <div className="SliderWrapper" style={{ transform }}>
        <Slider>{createContent()}</Slider>
      </div>
      <div className="WhiteSpaceWrapper">{createContent(true)}</div>
    </>
  )
}

export default ContentAndImageCarousel
