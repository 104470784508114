/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import Dropdown from "Components/Dropdown"
import { useSharedContext } from "Src/contexts/SharedContext"
import { languages } from "Utils/Language"
import "./LocaleRedirection.style.scss"
import CookieService from "Utils/CookieService"
import { isBrowser } from "Utils/Helpers"
import { useLocation } from "@reach/router"
import axios from "axios"
import { getGeolocation } from "Utils/WpRequests"

export const LocaleRedirection = props => {
  const { navigateToTranslation } = useSharedContext()

  const [shouldRender, setShouldRender] = useState(false)
  const [selectedSite, setSelectedSite] = useState({})
  const [suggestionCode, setSuggestionCode] = useState("")

  const { key, pathname: path } = useLocation()

  const pathname = path?.includes("/") ? path?.split("/")?.[1] : path

  const handleSiteChose = selectedSite => {
    setSelectedSite(selectedSite)
  }

  const handleClose = (changeLanguage = true) => {
    setShouldRender(false)

    if (changeLanguage) {
      languages[selectedSite?.code].base = window.location.origin
      navigateToTranslation(languages[selectedSite?.code])
    }
  }

  // we do not want to display this component if the choice has already been made via LocaleSelector component
  const selectedLocale = CookieService.get("bf_locale")

  useEffect(() => {
    const getIp = async () => {
      const ipAddress = await axios.get("https://api.ipify.org?format=json")
      const geo = await getGeolocation(ipAddress?.data?.ip, () => {})

      if (geo?.postal?.code) {
        if (geo?.country?.iso_code === "CA") {
          if (geo?.subdivisions?.[0]?.iso_code === "QC") {
            if (pathname === "qc-en" || pathname === "qc-fr") {
              return setShouldRender(false)
            }

            setShouldRender(true)
            setSelectedSite(languages["qc_fr"])
            return setSuggestionCode("qc_fr")
          }

          if (pathname === "ca-en") return setShouldRender(false)

          setShouldRender(true)
          setSelectedSite(languages["ca_en"])
          return setSuggestionCode("ca_en")
        }

        setShouldRender(false)
        setSelectedSite(languages["us_en"])
        return setSuggestionCode("us_en")
      }
    }

    getIp()
  }, [key, pathname])

  if (!isBrowser || selectedLocale) return null

  const { t } = props

  const label = selectedSite?.label

  return (
    <div className={`LocaleRedirection ${shouldRender ? "isActive" : ""}`}>
      <div className="ContentWrapper">
        <div className="Content">
          <span className="Text">
            {t("localeRedirection.textPartOne")}
            {<strong>{label}</strong>}
            {t("localeRedirection.textPartTwo")}
          </span>
          <Dropdown
            className="LanguageSelector"
            onOptionClick={handleSiteChose}
            options={languages}
            placeholderText={label}
            preselected={languages[suggestionCode]}
          />
          <button className="ContinueButton" onClick={() => handleClose()}>
            {t("header.continue")}
          </button>
        </div>
        <button className="CloseButton" onClick={() => handleClose(false)} />
      </div>
    </div>
  )
}

export default withTranslation()(LocaleRedirection)
