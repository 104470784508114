/* eslint-disable react/prop-types */
import React, { useState } from "react"
import { CarouselProvider, ButtonBack, ButtonNext } from "pure-react-carousel"
import ActiveSlide from "Components/ActiveSlide"
import { createReactFromNode } from "Utils/ReactFromNode"
import ProgressiveImage from "Components/ProgressiveImg"
import ContentAndImageCarousel from "./ContentAndImageCarousel.component"

const ContentAndImageCarouselWrapper = ({ node }) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const createChildrenObject = children => {
    return children.reduce((acc, { children, name, attribs }, index) => {
      const { src, class: className } = attribs
      const key = index

      if (name === "img") {
        if (!src) return acc

        acc.image = <ProgressiveImage {...attribs} key={key} />

        return acc
      }

      if (children.length) {
        const data = createReactFromNode(children)

        if (data) {
          const CustomTag = name
          const { class: attrbsClassName, ...attributes } = attribs

          acc[className.toLowerCase()] = (
            <CustomTag {...attributes} className={attrbsClassName} key={key}>
              {data}
            </CustomTag>
          )

          return acc
        }
      }

      return acc
    }, {})
  }

  const createChildrenMap = () => {
    return node.children.reduce((acc, { children }) => {
      const subChildObject = createChildrenObject(children)

      if (!Object.keys(subChildObject).length) return acc

      acc.push(subChildObject)

      return acc
    }, [])
  }

  const childrenMap = createChildrenMap()

  return (
    <div className="ContentAndImageCarousel">
      <CarouselProvider
        totalSlides={childrenMap.length}
        naturalSlideWidth={300}
        naturalSlideHeight={300}
        visibleSlides={1}
        className="Slider"
      >
        <ActiveSlide
          onSlideChange={currentSlide => setCurrentSlide(currentSlide)}
          currentSlide={currentSlide}
        >
          <ContentAndImageCarousel
            node={node}
            currentSlide={currentSlide}
            childrenMap={childrenMap}
            onSlideChange={currentSlide => setCurrentSlide(currentSlide)}
          />
        </ActiveSlide>
        <ButtonBack className="Arrow" />
        <ButtonNext className="Arrow isRight" />
      </CarouselProvider>
    </div>
  )
}

export default ContentAndImageCarouselWrapper
