import React from "react"
import BlogPostCard from "Components/BlogPostCard"

import "./BlogPostMostPopularPosts.style.scss"

const BlogPostMostPopularPosts = ({ title, postMap }) => {
  const renderSinglePost = post => (
    <BlogPostCard post={post} showedElems={["image", "categories", "title"]} />
  )

  const renderTitle = () => {
    if (!title) {
      return null
    }
    return <h2 className="BlogBlockTitle">{title}</h2>
  }

  return (
    <div className="BlogPostMostPopularPosts">
      {renderTitle()}
      <div className="PostsWrapper">{postMap.map(renderSinglePost)}</div>
    </div>
  )
}

export default BlogPostMostPopularPosts
