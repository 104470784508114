import React from "react"
import Video from "./Video.component"

const VideoContainer = ({
  videoUrl = "",
  posterUrl = "",
  microcopy = "",
  heading = "",
  subHeading = "",
  text = "",
  videoHeight = "",
  videoIsAutoPlay = false,
  videoIsLoop = false,
  videoPlayButtonText = "",
  videoPlayButtonId = "default",
  videoWidthId = "default",
  videoIsMute = false,
  videoIsContentVisibleOnPlay = false,
  videoOverlayIsEnabled = false,
  videoOverlayColor = { hex: "", opacity: 1, rgb: {} },
  contentAlignment = "left",
  button = "",
  videoStyle = "",
  elemsTransition,
}) => {
  const content = {
    microcopy,
    heading,
    subHeading,
    text,
    button,
  }

  return (
    <Video
      videoUrl={videoUrl}
      posterUrl={posterUrl}
      content={content}
      videoPlayButtonText={videoPlayButtonText}
      videoHeight={videoHeight}
      videoIsAutoPlay={videoIsAutoPlay}
      videoIsLoop={videoIsLoop}
      videoIsMute={videoIsMute}
      videoPlayButtonId={videoPlayButtonId}
      videoIsContentVisibleOnPlay={videoIsContentVisibleOnPlay}
      contentAlignment={contentAlignment}
      videoOverlayIsEnabled={videoOverlayIsEnabled}
      videoOverlayColor={videoOverlayColor}
      videoWidthId={videoWidthId}
      videoStyle={videoStyle}
      elemsTransition={elemsTransition}
    />
  )
}

export default VideoContainer
