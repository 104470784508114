import React from "react"
import { htmlToReact } from "Utils/HtmlParser"
import "./ComparisonBlockTable.style.scss"

const ComparisonBlockTable = ({ body = [], borderColor = "" }) => {
  const renderCell = ({ content }) => (
    <div className="Cell">{htmlToReact(content)}</div>
  )

  const renderRow = ({ cells }) => (
    <div className="Row">{cells.map(renderCell)}</div>
  )

  const style = { borderColor }

  return (
    <div className="ComparisonBlockTable">
      <div className="Body" style={style}>
        {body.map(renderRow)}
      </div>
    </div>
  )
}

export default ComparisonBlockTable
