import React, { useEffect } from "react"
import { globalHistory } from "@reach/router"

import { setLanguage } from "Utils/Language"
import { isBrowser, saveUTMDataString } from "Utils/Helpers"
import Layout from "./Layout.component"

const LayoutContainer = ({ location, ...props }) => {
  useEffect(() => {
    // This is used to set language on initial build
    // If not set prerender language would default to us-en for all pages
    if (!isBrowser) {
      setLanguage(undefined, undefined, location)
    }

    const historyUnsubscribe = globalHistory.listen(() => setLanguage())
    saveUTMDataString()

    return () => {
      historyUnsubscribe()
    }
  }, [location])

  return <Layout {...props} />
}

export default LayoutContainer
