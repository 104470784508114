import React from "react"
import { withTranslation } from "react-i18next"
import Dropdown from "Components/Dropdown"
import "./FooterRegion.style.scss"
import { getLanguage, languages } from "Utils/Language"
import { useSharedContext } from "Src/contexts/SharedContext"
import { isBrowser } from "Utils/Helpers"
import { navigate } from "gatsby"

const FooterRegion = ({ t, translationSlugs }) => {
  const {
    navigateToTranslation,
    pathName,
    translationSlugs: contextSlugs,
  } = useSharedContext()

  const changeLanguage = ({ code: languageCode }) => {
    if (isBrowser) {
      const targetLanguageSlug = languages?.[languageCode]?.url?.split("/")?.[1]
      if (
        ["/us-en/", "/us-es/", "/ca-en/", "/qc-fr/", "/qc-en/"].includes(
          pathName,
        ) ||
        translationSlugs === null
      ) {
        navigateToTranslation(languages?.[languageCode])
        navigate(`/${targetLanguageSlug}/`)
      } else {
        const idTargetPage = contextSlugs?.[targetLanguageSlug]
        const targetPath = translationSlugs?.[idTargetPage]

        const newPath = targetPath
          ? `/${targetLanguageSlug}/${targetPath}/`
          : `/${targetLanguageSlug}/`

        navigate(newPath)
      }
    }
  }

  const { label } = getLanguage()

  return (
    <div className="FooterRegion isActive">
      <Dropdown
        className="LanguageSelector"
        onOptionClick={changeLanguage}
        options={languages}
        placeholderText={label}
        isChangeOnLanguageChange
      />
    </div>
  )
}

export default withTranslation()(FooterRegion)
