import React from "react"
import { useSharedContext } from "Src/contexts/SharedContext"
import LocationFinder from "./LocationFinder.component"

const LocationFinderContainer = props => {
  const { isOpen, zipCode, changeState, locationZipCode } = useSharedContext()

  return (
    <LocationFinder
      {...props}
      contextIsOpen={isOpen}
      contextZipCode={zipCode}
      contextLocationZipCode={locationZipCode}
      changeContextState={changeState}
    />
  )
}

export default LocationFinderContainer
