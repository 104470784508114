import React, { useRef, useEffect } from "react"
import BlogPostCard from "Components/BlogPostCard"
import { LOAD_MORE_INCREMENT } from "Components/BlogOlderPosts/BlogOlderPosts.container"
import "./BlogOlderPosts.style.scss"

const BlogOlderPosts = ({
  postMap = [],
  isAllPostsVisible,
  handleLoadMore,
  blockTitle,
  loadMoreButton,
  backgroundColor,
  activePageCategoryName,
  setToInitialShowedPostCount,
}) => {
  const ref = useRef(null)

  useEffect(() => {
    const scrollToFirstNewPost = () => {
      if (postMap.length > LOAD_MORE_INCREMENT && ref.current) {
        const headerHeight = 150
        setTimeout(() => {
          window.scrollTo({
            top: ref.current.offsetTop - headerHeight,
            behavior: "smooth",
          })
        }, 1)
      }
    }

    scrollToFirstNewPost()
    if (setToInitialShowedPostCount)
      setToInitialShowedPostCount(activePageCategoryName)
  }, [postMap.length, activePageCategoryName, setToInitialShowedPostCount])

  return (
    <div className="BlogOlderPosts" style={{ backgroundColor }}>
      <div className="ContentWrapper">
        {blockTitle && <h2 className="BlogBlockTitle">{blockTitle}</h2>}
        {postMap.map((post, index) => (
          <div key={post.id} ref={index === postMap.length - 1 ? ref : null}>
            <BlogPostCard
              post={post}
              showedElems={["image", "category", "title"]}
            />
            {index !== postMap.length - 1 && <div className="Line" />}
          </div>
        ))}
        {!isAllPostsVisible && loadMoreButton && (
          <button
            className="Button-Secondary LoadMoreButton"
            onClick={handleLoadMore}
          >
            {loadMoreButton}
          </button>
        )}
      </div>
    </div>
  )
}

export default BlogOlderPosts
