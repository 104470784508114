/* eslint-disable jsx-a11y/control-has-associated-label, react/no-danger */
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import "./NavigationMenu.style.scss"
import Arrow from "Assets/Arrow.svg"
import { useSharedContext } from "Src/contexts/SharedContext"
import Link from "Components/Link"
import MenuIcon from "Assets/Menu.svg"
import { renderSidebarWidgets } from "Utils/Sidebars"
import { getMenuLink } from "Utils/Link"
import { defaultLanguageCode, languages } from "Utils/Language"
import htmlParser from "Utils/HtmlParser"
import ProgressiveImage from "Components/ProgressiveImg"
import { getPreviewImageSrc } from "Utils/Helpers"

export const FIRST_LEVEL_MENU = "Main"
export const SECOND_LEVEL_MENU = "Submenu"
export const THIRD_LEVEL_MENU = "ThirdLevelMenu"

const NavigationMenu = ({
  t,
  allMenus,
  logo,
  languageCode = defaultLanguageCode,
}) => {
  const { isDesktop, getTranslationUrl, hasPageHeader } = useSharedContext()

  const [showNavigationOverlay, setShowNavigationOverlay] = useState(false)
  const [activeMenuItem, setActiveMenuItem] = useState(null)
  const [prevActiveMenuItem, setPrevActiveMenuItem] = useState(null)
  const [activeMenuLevel, setActiveMenuLevel] = useState(null)
  const [prevActiveMenuLevel, setPrevActiveMenuLevel] = useState(null)
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false)

  React.useEffect(() => {
    if (!isDesktop) {
      document.body.style.overflow = showNavigationOverlay
        ? "hidden"
        : "initial"
    }
  }, [showNavigationOverlay, isDesktop])

  const resetStates = () => {
    setPrevActiveMenuItem(null)
    setActiveMenuItem(null)
    setActiveMenuLevel(null)
    setPrevActiveMenuLevel(null)
  }

  const toggleNavigationOverlay = () => {
    setShowNavigationOverlay(!showNavigationOverlay)
    setIsSubmenuVisible(false)
    resetStates()
  }

  const hasFeaturedChild = children => {
    const featuredList = children.map(
      ({ featured_image: featuredImage }) => featuredImage,
    )
    return !!featuredList.length
  }

  const changeActiveMenuLevel = menuLevel => {
    const allLevels = [FIRST_LEVEL_MENU, SECOND_LEVEL_MENU, THIRD_LEVEL_MENU]
    const clickedItemIndex = allLevels.findIndex(
      clickedLevel => clickedLevel === menuLevel,
    )
    const nextLevel = allLevels[clickedItemIndex + 1]

    if (activeMenuLevel === THIRD_LEVEL_MENU) {
      setPrevActiveMenuLevel(activeMenuLevel)
      setActiveMenuLevel(menuLevel)
    } else {
      setPrevActiveMenuLevel(menuLevel)
      setActiveMenuLevel(nextLevel)
    }
  }

  const changeActiveMenuItem = menuItem => {
    setPrevActiveMenuItem(activeMenuItem)
    setActiveMenuItem(menuItem)
  }

  const handleArrowOnClick = (title, menuLevel) => {
    changeActiveMenuItem(title)
    changeActiveMenuLevel(menuLevel)
  }

  const renderArrow = (title, menuLevel) => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={`NavigationMenu-${menuLevel}-Item-Arrow-Wrapper`}
      onClick={() => !isDesktop && handleArrowOnClick(title, menuLevel)}
    >
      <Arrow className={`NavigationMenu-${menuLevel}-Item-Arrow`} data-image />
    </div>
  )

  const renderLanguageSubmenu = () => {
    const title = "Language"
    const menuLevel = SECOND_LEVEL_MENU
    const isActive =
      (title === activeMenuItem && menuLevel === activeMenuLevel) ||
      (title === prevActiveMenuItem && menuLevel === prevActiveMenuLevel)
        ? " isActive"
        : ""

    const isVisible = isSubmenuVisible ? " isVisible" : ""

    return (
      <div
        className={`NavigationMenu-${menuLevel}-Wrapper${isActive}${isVisible}`}
        aria-label={title}
      >
        <div className={`NavigationMenu-${menuLevel}`}>
          <ul className={`NavigationMenu-${menuLevel}-Item-Wrapper`}>
            {[...Object.keys(languages)].map(key => {
              const { label } = languages[key]

              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                <li key={key} className={`NavigationMenu-${menuLevel}-Item`}>
                  <a href={getTranslationUrl(languages[key])}>{label}</a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }

  const renderLanguageMainMenu = () => {
    const title = "Language"
    const menuLevel = FIRST_LEVEL_MENU

    return (
      <ul className={`NavigationMenu-${menuLevel} Language`}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <li
          className={`NavigationMenu-${menuLevel}-Item hasChildren`}
          onClick={() =>
            !isDesktop && handleArrowOnClick("Language", menuLevel)
          }
        >
          <span className={`NavigationMenu-${menuLevel}-Item-Language`}>
            {title}
          </span>
          <div className={`NavigationMenu-${menuLevel}-Item-Arrow-Wrapper`}>
            <Arrow
              className={`NavigationMenu-${menuLevel}-Item-Arrow`}
              data-image
            />
          </div>
          {renderLanguageSubmenu()}
        </li>
      </ul>
    )
  }

  const renderNavigationItem = (menuItem, hasChildren, menuLevel, imageUrl) => {
    const { object_slug, title, type, url: menuUrl } = menuItem

    const { url, isExternal } = getMenuLink({ type, object_slug, url: menuUrl })

    return (
      <>
        <Link
          className={`NavigationMenu-${menuLevel}-Item-Link`}
          to={url}
          isExternal={isExternal}
          onClick={toggleNavigationOverlay}
        >
          {!!imageUrl && (
            <ProgressiveImage
              src={getPreviewImageSrc(imageUrl)}
              largeSrc={imageUrl}
              className={`NavigationMenu-${menuLevel}-Item-Image`}
            />
          )}
          <span>{htmlParser(title)}</span>
        </Link>
        {hasChildren && !imageUrl && renderArrow(title, menuLevel)}
      </>
    )
  }

  const renderThirdLevelMenu = submenuItem => {
    const { title, children } = submenuItem
    // const children = children
    const menuLevel = THIRD_LEVEL_MENU
    const isFeatured = hasFeaturedChild(children) ? " isFeatured" : ""
    const isActive =
      title === activeMenuItem && menuLevel === activeMenuLevel
        ? " isActive"
        : ""
    const menuItems = children.filter(
      ({ featured_image: featuredImage }) => !featuredImage,
    )
    const featuredItems = children.filter(
      ({ featured_image: featuredImage }) => featuredImage,
    )
    const isOnlyFeatured = menuItems.length ? "" : " onlyFeatured"

    return (
      <div
        className={`NavigationMenu-${menuLevel}-Wrapper${isActive}${isFeatured}`}
        aria-label={title}
      >
        <ul
          className={`NavigationMenu-${menuLevel}-Item-Wrapper${isOnlyFeatured}`}
        >
          {menuItems.map(child => {
            const { object_id: key } = child

            return (
              <li key={key} className={`NavigationMenu-${menuLevel}-Item`}>
                {renderNavigationItem(child, "", menuLevel)}
              </li>
            )
          })}
        </ul>
        <div className={`NavigationMenu-FeaturedMenu${isOnlyFeatured}`}>
          {featuredItems.map(child => {
            const { object_id: key, featured_image: featuredImage } = child
            return (
              <div key={key} className="NavigationMenu-FeaturedMenu-Item">
                {renderNavigationItem(child, "", "FeaturedMenu", featuredImage)}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderSubmenu = menuItem => {
    const { title, children } = menuItem
    // const children = children
    const menuLevel = SECOND_LEVEL_MENU
    const isActive =
      (title === activeMenuItem && menuLevel === activeMenuLevel) ||
      (title === prevActiveMenuItem && menuLevel === prevActiveMenuLevel)
        ? " isActive"
        : ""

    const isVisible = isSubmenuVisible ? " isVisible" : ""
    const menuItems = children.filter(
      ({ featured_image: featuredImage }) => !featuredImage,
    )
    const featuredItems = children.filter(
      ({ featured_image: featuredImage }) => featuredImage,
    )
    const isFeatured = hasFeaturedChild(children) ? " isFeatured Submenu" : ""
    const isOnlyFeatured = menuItems.length ? "" : " onlyFeatured"

    return (
      <div
        className={`NavigationMenu-${menuLevel}-Wrapper${isActive}${isVisible}`}
        aria-label={title}
      >
        <div className={`NavigationMenu-${menuLevel}`}>
          <ul
            className={`NavigationMenu-${menuLevel}-Item-Wrapper${isOnlyFeatured}`}
          >
            {menuItems.map((child, index) => {
              const {
                children,
                object_id,
                featured_image: featuredImage,
              } = child

              const isFeaturedItem = featuredImage ? " isFeatured" : ""
              const key = `${object_id}-${index}`
              const hasChildren =
                children &&
                children.filter(
                  ({ featured_image: featuredImage }) => !featuredImage,
                ).length
                  ? " hasChildren"
                  : ""

              return (
                <li
                  key={key}
                  className={`NavigationMenu-${menuLevel}-Item${hasChildren}${isFeaturedItem}`}
                >
                  {renderNavigationItem(child, hasChildren, menuLevel)}
                  {children && renderThirdLevelMenu(child)}
                </li>
              )
            })}
          </ul>
          <div className={`${isFeatured}${isOnlyFeatured}`}>
            <div className="NavigationMenu-FeaturedMenu Submenu">
              {featuredItems.map(child => {
                const { object_id: key, featured_image: featuredImage } = child

                return (
                  <div key={key} className="NavigationMenu-FeaturedMenu-Item">
                    {renderNavigationItem(
                      child,
                      "",
                      "FeaturedMenu",
                      featuredImage,
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderNavigationMenu = (allMenus, renderLanguageSelector) => {
    if (!allMenus) return null

    return allMenus.nodes.map(menu => {
      const menuLanguage = menu.slug.substr(menu.slug.lastIndexOf("-") + 1)
      const menuLevel = FIRST_LEVEL_MENU

      if (menuLanguage !== languageCode) return null

      return (
        <React.Fragment key={menu.slug}>
          <ul className={`NavigationMenu-${menuLevel}`}>
            {menu.items.map(menuItem => {
              const hasChildren = menuItem.children ? " hasChildren" : ""

              return (
                // eslint-disable-next-line
                <li
                  key={menuItem.object_id}
                  className={`NavigationMenu-${menuLevel}-Item${hasChildren}`}
                  onMouseEnter={() => setIsSubmenuVisible(true)}
                  onMouseLeave={() => setIsSubmenuVisible(false)}
                >
                  {renderNavigationItem(menuItem, hasChildren, menuLevel)}
                  {hasChildren && renderSubmenu(menuItem)}
                </li>
              )
            })}
          </ul>
          {renderLanguageSelector && renderLanguageMainMenu()}
        </React.Fragment>
      )
    })
  }

  const handleGoBack = () => {
    if (!prevActiveMenuItem || activeMenuLevel === SECOND_LEVEL_MENU) {
      resetStates()
    } else {
      changeActiveMenuItem(prevActiveMenuItem)
      changeActiveMenuLevel(prevActiveMenuLevel)
    }
  }

  const renderNavigationMenuOverlay = (
    allMenus,
    logo,
    renderLanguageSelector,
  ) => {
    const isVisible = !activeMenuItem ? " isVisible" : ""
    const isActive = showNavigationOverlay ? " isActive" : ""

    return (
      <div className={`NavigationMenu-Overlay${isActive}`}>
        <div className="NavigationMenu-Overlay-Top">
          <Link
            to="/"
            className={`NavigationMenu-Logo${isVisible}`}
            onClick={toggleNavigationOverlay}
          >
            {renderSidebarWidgets(logo, languageCode)}
          </Link>
          <button className="NavigationMenu-Button" onClick={handleGoBack}>
            {activeMenuItem && (
              <Arrow className="NavigationMenu-Button-Icon" data-image />
            )}
            {activeMenuItem && (
              <span className="NavigationMenu-Button-Title">
                {activeMenuItem}
              </span>
            )}
          </button>
          <button
            className="NavigationMenu-Button-Close"
            onClick={toggleNavigationOverlay}
          />
        </div>
        <div className="NavigationMenu-Overlay-Body">
          {renderNavigationMenu(allMenus, renderLanguageSelector)}
        </div>
      </div>
    )
  }

  const renderNavigationButton = () => (
    <button className="NavigationMenu-Button" onClick={toggleNavigationOverlay}>
      <MenuIcon className="NavigationMenu-Button-Icon" data-image />
      <span className="NavigationMenu-Button-Title">{t("header.menu")}</span>
    </button>
  )

  if (!hasPageHeader) return null

  const renderLanguageSelector = true

  return (
    <div className="NavigationMenu">
      {renderNavigationMenu(allMenus, !renderLanguageSelector)}
      {renderNavigationButton()}
      {renderNavigationMenuOverlay(allMenus, logo, renderLanguageSelector)}
    </div>
  )
}

export default withTranslation()(NavigationMenu)
