import React from "react"
import { I18nextProvider, useTranslation } from "react-i18next"
import i18next from "./config"

export const withTrans = WrappedComponent => props => {
  const { t } = useTranslation()

  return (
    <I18nextProvider i18n={i18next}>
      <WrappedComponent {...props} t={t} language={i18next.language} />
    </I18nextProvider>
  )
}
