import React, { createContext, useContext, useState } from "react"
import { submitNinjaForm } from "Utils/WpRequests"

export const NinjaContext = createContext()

export const NinjaContextProvider = ({ children }) => {
  const [nfAssets, setNfAssets] = useState(null)
  const [updatedNfAssets, setUpdatedNfAssets] = useState(null)
  async function formSubmission(
    formID,
    fieldState,
    settings,
    extra,
    leadBoolValue,
  ) {
    const data = await submitNinjaForm(
      formID,
      fieldState,
      settings,
      extra,
      leadBoolValue,
      data => {},
    )
    return data
  }

  const initialFieldState = fields => {
    fields.map(field => ({
      ...field,
    }))
    return fields
  }

  const contextValue = {
    formSubmission,
    initialFieldState,
    nfAssets,
    setNfAssets,
    updatedNfAssets,
    setUpdatedNfAssets,
  }

  return (
    <NinjaContext.Provider value={contextValue}>
      {children}
    </NinjaContext.Provider>
  )
}

export const useNinjaContextProvider = () => useContext(NinjaContext)
