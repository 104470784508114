import React from "react"
import ColorSelectorTool, {
  MODEL_COUNT_SLIDE,
} from "./ColorSelectorTool.component"

const ColorSelectorToolContainer = ({ node }) => {
  const parsedData = JSON.parse(node.attribs.data)

  const getModelSlideMap = modelMap => {
    return modelMap.reduce((acc, model, index) => {
      const slideIndex = Math.floor(index / MODEL_COUNT_SLIDE)

      if (!acc[slideIndex]) {
        acc[slideIndex] = []
      }

      acc[slideIndex].push(model)

      return acc
    }, [])
  }

  return (
    <ColorSelectorTool
      {...parsedData}
      modelSlideMap={getModelSlideMap(parsedData.modelMap)}
    />
  )
}

export default ColorSelectorToolContainer
