import React from "react"
import { useSharedContext } from "Src/contexts/SharedContext"
import TagsWrapper from "./TagsWrapper.component"

const TagsWrapperContainer = props => {
  const { activePageTag } = useSharedContext()

  if (!activePageTag.id) {
    return null
  }

  return <TagsWrapper {...props} activePageTag={activePageTag} />
}

export default TagsWrapperContainer
