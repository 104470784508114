import React from "react"
import { useSharedContext } from "Src/contexts/SharedContext"
import { getLanguage } from "Utils/Language"

import Header from "./Header.component"
import { useHeader } from "Src/hooks/useHeader"

import { useLocation } from "@reach/router"

export const HeaderContainer = props => {
  const { hasPageHeader } = useSharedContext()
  const { data } = useHeader()
  const { pathname } = useLocation()

  const renderContent = data => {
    const keywords = [
      "commercial-sales",
      "ventes-commerciales",
      "hospitality",
      "housing-authority",
      "senior-living",
      "student-housing",
    ]

    const isCSPage = keywords.some(keyword => pathname?.includes(keyword))

    const { language } = getLanguage()

    return (
      <Header
        {...props}
        data={data}
        hasPageHeader={hasPageHeader}
        isCSPage={isCSPage}
        language={language}
      />
    )
  }

  return renderContent(data)
}

export default HeaderContainer
