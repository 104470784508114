import React from "react"
import BlogPostCard from "Components/BlogPostCard"

import "./BlogSecondaryPosts.style.scss"

const BlogSecondaryPosts = ({
  recent_posts = [{}],
  blockTitle,
  backgroundColor,
}) => {
  const renderSinglePost = post => {
    const { wordpress_id } = post

    return (
      <BlogPostCard
        key={wordpress_id}
        post={post}
        customRenderAfterImage={() => (
          <div className="SinglePostRedirectButton" />
        )}
        showedElems={["image", "category", "title"]}
      />
    )
  }

  const renderTitle = () => {
    if (!blockTitle) {
      return null
    }
    return <h2 className="BlogBlockTitle">{blockTitle}</h2>
  }

  return (
    <div className="BlogSecondaryPosts" style={{ backgroundColor }}>
      <div className="ContentWrapper">
        {renderTitle()}
        <div className="Posts">{recent_posts.map(renderSinglePost)}</div>
      </div>
    </div>
  )
}

export default BlogSecondaryPosts
