import React from "react"
import BlockTransition from "./BlockTransition.component"

const BlockTransitionContainer = ({
  children,
  blockTransition = {},
  elemsTransition = [],
}) => {
  return (
    <BlockTransition
      blockTransition={blockTransition}
      elemsTransition={elemsTransition}
    >
      {children}
    </BlockTransition>
  )
}

export default BlockTransitionContainer
