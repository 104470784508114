import React, { useEffect, useState } from "react"
import { getLanguage } from "Utils/Language"
import axios from "axios"
import { Rings } from "react-loader-spinner"

import "./Autocomplete.scss" // Import the SCSS file

const AddressAutocomplete = ({ handleSelectValue, placeholder }) => {
  const [value, setValue] = useState("")
  const [suggestions, setSuggestions] = useState([])

  const [countrySlug, setCountrySlug] = useState(null)

  const { code } = getLanguage()

  const [showSpinner, setShowSpinner] = useState(false)

  // useEffect to update country slug
  useEffect(() => {
    if (
      code === "us-en" ||
      code === "us_en" ||
      code === "us-es" ||
      code === "us_es"
    ) {
      setCountrySlug("us")
    }
    if (
      code === "ca-en" ||
      code === "ca_en" ||
      code === "qc-fr" ||
      code === "qc-en" ||
      code === "qc_fr" ||
      code === "qc_en"
    ) {
      setCountrySlug("ca")
    }
    return () => {}
  }, [code, countrySlug])

  const headers = {
    "Content-Type": "application/json",
    "x-functions-key": `${process.env.GATSBY_ADDRESS_SUGGESTION_API_KEY}`,
  }

  const fetchSuggestions = async query => {
    if (!query) {
      setSuggestions([])
      return
    }
    setShowSpinner(true)
    try {
      const res = await axios.get(
        `${process.env.GATSBY_ADDRESS_SUGGESTION_API_URL}/suggestions?query=${query}&countries=${countrySlug}`,
        { headers },
      )
      setSuggestions(res.data)
      setShowSpinner(false)
    } catch (error) {
      console.error("Error fetching address suggestions:", error)
    }
  }

  const handleSelectAddress = async address => {
    setValue(address.fullAddress) // Adjust based on your API response structure

    setSuggestions([]) // Clear suggestions after selection

    if (address.id) {
      try {
        const details = await axios.get(
          `${process.env.GATSBY_ADDRESS_SUGGESTION_API_URL}/retrieve?id=${address.id}`,
          { headers },
        )

        handleSelectValue(details.data)
      } catch (error) {
        console.error("Error fetching address suggestions:", error)
      }
    }
  }

  return (
    <div className="autocomplete-container">
      <input
        type="text"
        value={value}
        onChange={e => {
          setValue(e.target.value)
          fetchSuggestions(e.target.value)
        }}
        placeholder={placeholder}
        className="autocomplete-input"
      />

      <Rings
        visible={showSpinner}
        height="40px"
        width="40px"
        color="#007377"
        ariaLabel="rings-loading"
        wrapperStyle={{}}
        wrapperClass="spinner-suggestion"
      />

      {suggestions.length > 0 && (
        <ul className="autocomplete-dropdown">
          {suggestions.map(suggestion => (
            <li
              className="autocomplete-item"
              key={suggestion.id} // Adjust based on your API response structure
              onClick={() => handleSelectAddress(suggestion)}
            >
              {suggestion.fullAddress}{" "}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default AddressAutocomplete
