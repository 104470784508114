import React from "react"
import htmlParser from "Utils/HtmlParser"
import { defaultLanguageCode, formatFromWPLanguage } from "./Language"
import { formatToWpLanguage } from "./Language"

const hasSidebarForLocale = (widgets, languageCode) =>
  widgets?.nodes
    .map(widget => {
      const { language } = widget

      return (
        (language === null ||
          formatFromWPLanguage(language) === languageCode) &&
        widget.rendered !== ""
      )
    })
    .some(value => value)

const renderSidebarWidgets = (
  widgets,
  languageCode = defaultLanguageCode,
  customClassName = "",
  parserProps = {},
) => {
  if (!widgets) return null

  const { uuid, props, disableImagePreview } = parserProps

  if (widgets.nodes.length === 3) {
    if (widgets.nodes[0].language === null) {
      widgets.nodes = widgets.nodes.slice(1)
      widgets.nodes.push({ ...widgets.nodes[0] })
      widgets.nodes.push({ ...widgets.nodes[0] })
      widgets.nodes[2].language = "us-en"
      widgets.nodes[3].language = "qc-en"
    }
  }

  return widgets.nodes.map((widget, i) => {
    if (widget.language === "") {
      if (
        widget &&
        widget.block_data &&
        widget.block_data.length > 0 &&
        widget.block_data[0].attrs &&
        widget.block_data[0].attrs.pll_lang
      ) {
        widget.language = widget.block_data[0].attrs.pll_lang
      }
    }

    if (
      widget.language !== formatToWpLanguage(languageCode) &&
      widget.language !== null
    )
      return null

    const { custom_class_name: className, name, rendered } = widget
    return (
      <div
        className={`${className} ${customClassName}`}
        // eslint-disable-next-line react/no-array-index-key
        key={`${name}-${i}`}
      >
        {htmlParser(rendered, uuid, props, disableImagePreview)}
      </div>
    )
  })
}

export { hasSidebarForLocale, renderSidebarWidgets }
