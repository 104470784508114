import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { debounce } from "throttle-debounce"
import { searchPages } from "Utils/WpRequests"
import { formatToWpLanguage, getLanguage } from "Utils/Language"
import SearchIcon from "Assets/Search.svg"
import "./Search.style.scss"
import { navigate, StaticQuery, graphql } from "gatsby"
import Link from "Components/Link"
import { getPathname } from "Utils/Helpers"
import { renderSidebarWidgets } from "Utils/Sidebars"

const SearchBar = ({ t }) => {
  const [showSearchOverlay, setShowSearchOverlay] = useState(false)
  const [loading, setLoading] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [totalSearchResults, setTotalSearchResults] = useState([])
  const [search, setSearch] = useState("")
  const [widgetListenersInitialized, setWidgetListenersInitialized] =
    useState(false)
  const { code: languageCode, url: languageUrl } = getLanguage()
  const maxSearchLength = 200
  const maxSearchResults = 9
  const debounceTime = 300

  const onQuickLinkClick = () => {
    setShowSearchOverlay(false)
  }

  useEffect(() => {
    setSearchResults([])
    setTotalSearchResults([])
    setSearch("")

    // Workaround for adding event listeners to WP "Quick Links" widget HTML code
    if (!widgetListenersInitialized && showSearchOverlay) {
      // Set up event listener for all quick links
      document
        .querySelectorAll(".sw-bathfitter-widget-quick-links-item")
        .forEach(element => {
          element.addEventListener("click", onQuickLinkClick)
        })

      // Prevent event listeners from being initialized again
      setWidgetListenersInitialized(true)
    }

    return () => {
      // Remove event listener once the search bar is closed to avoid memory leaks
      if (!showSearchOverlay && widgetListenersInitialized) {
        document
          .querySelectorAll(".sw-bathfitter-widget-quick-links-item")
          .forEach(element => {
            element.removeEventListener("click", onQuickLinkClick)
          })
      }
    }
  }, [showSearchOverlay, widgetListenersInitialized])

  const triggerOverlay = () => {
    setShowSearchOverlay(!showSearchOverlay)
  }

  const getSearchUrl = () => {
    const searchData = { s: search.trim(), p: 1 }
    const urlSearchParams = new URLSearchParams(searchData)
    const url = `${languageUrl}/search?${urlSearchParams.toString()}`

    return url
  }

  const debouncedSearch = debounce(debounceTime, searchPages)

  const searchResultsHandler = (res, err, { "x-wp-total": total }) => {
    if (err) {
      setTotalSearchResults([])
      setSearchResults([])
    } else {
      setTotalSearchResults(total)
      setSearchResults(res)
    }

    setLoading(false)
  }

  const searchPayload = {
    language: formatToWpLanguage(languageCode),
    page: 1,
    per_page: maxSearchResults,
    callback: searchResultsHandler,
  }

  const doSearch = ({ target: { value: searchStringInput } }) => {
    const searchString = searchStringInput.slice(0, maxSearchLength)

    setSearch(searchString)

    if (searchString.trim().length) {
      setLoading(true)
      debouncedSearch({ ...searchPayload, search: searchString })
    }
  }

  const toggleSearchOverlay = ({ target, currentTarget }) => {
    if (showSearchOverlay) {
      if (target === currentTarget) {
        triggerOverlay()
      }
    } else {
      triggerOverlay()
    }
  }

  const renderSearchSuggestionsList = () => {
    const searchTerm = search.trim()
    const escapedSearchTerm = searchTerm.replace(
      /[-/\\^$*+?.()|[\]{}]/g,
      "\\$&",
    )
    const replaceRegex = new RegExp(escapedSearchTerm, "gi")

    const suggestions = searchResults.map(result => {
      const tagsToReplace = []
      let tagId = 0
      const preservedText = result.title.replace(/<[^>]+>/g, match => {
        const replaceKey = `<<${tagId}>>`
        tagsToReplace.push({ key: replaceKey, html: match })
        tagId += 1
        return replaceKey
      })

      const highlightedText = preservedText.replace(
        replaceRegex,
        "<strong>$&</strong>",
      )

      result.title = tagsToReplace.reduce((text, tag) => {
        return text.replace(tag.key, tag.html)
      }, highlightedText)

      return result
    })

    if (!suggestions.length) {
      return <span className="Search-No-Results">{t("search.noResults")}</span>
    }

    return (
      <ul>
        {suggestions.map(({ url, title }) => (
          <li key={url}>
            <Link to={getPathname(url)} onClick={triggerOverlay}>
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Link>
          </li>
        ))}
      </ul>
    )
  }

  const handleInputKeyDown = ({ keyCode }) => {
    const enterKeyCode = 13
    const escKeyCode = 27

    switch (keyCode) {
      case escKeyCode:
        triggerOverlay()
        break
      case enterKeyCode:
        navigate(getSearchUrl())
        triggerOverlay()
        break
      default:
    }

    return null
  }

  const renderSearchTotals = () => {
    const searchString = search.trim()

    if (searchString.length && searchResults.length < totalSearchResults) {
      const url = getSearchUrl()

      return (
        <Link to={url} onClick={triggerOverlay} className="Total-Results-Link">
          {`${t("search.seeAll")} ${totalSearchResults} ${t("search.results")}`}
        </Link>
      )
    }

    return null
  }

  const renderSearch = () => {
    if (!showSearchOverlay) return null
    return (
      <StaticQuery
        query={graphql`
          query HomePageQuery {
            site {
              siteMetadata {
                description
              }
            }
          }
        `}
        render={({ searchBar }) => {
          const { code: languageCode } = getLanguage()
          const isSearchBarEmpty = !search.trim().length

          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div className="Search-Overlay" onClick={toggleSearchOverlay}>
              <div className="Search-Top-Bar">
                <div className="Search-Input-Container">
                  <SearchIcon className="Input-Search-Icon" data-image />
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button
                    className="Close-Button"
                    onClick={toggleSearchOverlay}
                  />
                  <input
                    className="Search-Input"
                    value={search}
                    ref={input => input && input.focus()}
                    onChange={event => doSearch(event)}
                    onKeyDown={handleInputKeyDown}
                    placeholder={t("search.searchPlaceholder")}
                    maxLength={maxSearchLength}
                  />
                  {renderSearchTotals()}
                </div>
              </div>
              {isSearchBarEmpty && (
                <div className="Search-Sidebar">
                  {renderSidebarWidgets(
                    searchBar,
                    languageCode,
                    "Search-Sidebar-Wrapper",
                  )}
                </div>
              )}
              {!isSearchBarEmpty && (
                <div className="Search-Results-Container">
                  <span className="Section-Header">
                    {t("search.suggestionsHeader")}
                  </span>
                  <div className="Section-Suggestions">
                    {loading ? (
                      <div>{t("search.loading")}</div>
                    ) : (
                      renderSearchSuggestionsList()
                    )}
                  </div>
                </div>
              )}
            </div>
          )
        }}
      />
    )
  }

  return (
    <div className="Search-Wrapper">
      <button className="Search-Button" onClick={toggleSearchOverlay}>
        <span>{t("search.search")}</span>
        <SearchIcon className="Search-Button-Icon" data-image />
      </button>
      {renderSearch()}
    </div>
  )
}

export default withTranslation()(SearchBar)
