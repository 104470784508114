import React, { useCallback } from "react"
import { useSharedContext } from "Src/contexts/SharedContext"
import { createReactFromNode } from "Utils/ReactFromNode"

const BookingConsultationBlockButton = ({ node }) => {
  const { setIsConsultaionModalOpen } = useSharedContext()

  const handleButtonClick = useCallback(() => {
    setIsConsultaionModalOpen(true)
  }, [setIsConsultaionModalOpen])

  return (
    <button className="PrimaryButton" onClick={handleButtonClick}>
      {createReactFromNode(node.children)}
    </button>
  )
}

export default BookingConsultationBlockButton
