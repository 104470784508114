import React from "react"

const createData = ({ data, children, name: Tag, attribs }, key = 0) => {
  if (data === undefined) {
    const { class: attrbsClassName, ...attributes } = attribs

    if (!children || !children.length) {
      return <Tag {...attributes} className={attrbsClassName} key={key} />
    }

    return (
      <Tag {...attributes} className={attrbsClassName} key={key}>
        {children.map(createData)}
      </Tag>
    )
  }

  return data
}

const createReactFromNode = data => {
  if (!data) {
    return data
  }

  if (Array.isArray(data)) {
    if (!data.length) return null

    return data.map(createData)
  }

  return createData(data)
}

// eslint-disable-next-line import/prefer-default-export
export { createReactFromNode }
