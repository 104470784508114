import React from "react"
import { getLanguage } from "Utils/Language"
import BlogPostLatestPosts from "./BlogPostLatestPosts.component"

const BlogPostLatestPostsContainer = ({ posts = {}, title = "" }) => {
  const POST_COUNT = 10
  const postMap = posts.recent_posts
    .filter(post => post.lang === getLanguage().url.replace("/", ""))
    .slice(0, POST_COUNT)

  return <BlogPostLatestPosts postMap={postMap} title={title} />
}

export default BlogPostLatestPostsContainer
