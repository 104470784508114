import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"

const defaultMeta = {
  title: "",
  description: "",
  imageSrc: "",
}

export const ShareWrapper = ({
  isActive,
  location,
  shareId,
  itemId,
  onIsNotActive = () => {},
  onIsActive = () => {},
  metaTitle = "",
  metaImageSrc = "",
  metaDescription = "",
  children,
  ...props
}) => {
  const [meta, setMeta] = useState(defaultMeta)
  const [initialMeta, setInitialMeta] = useState(defaultMeta)
  const [shouldUpdateToInitialMeta, setShouldUpdateToInitialMeta] =
    useState(false)

  const { href, hash } = location || {}
  const prevHref = useRef(href)

  useEffect(() => {
    const isValidUrl = hash === `${shareId}${itemId}`

    if (prevHref.current !== href) {
      if (isActive === props.prevIsActive) {
        if (!isValidUrl) {
          if (isActive) {
            onIsNotActive()
          }
        } else {
          changeMeta()
          if (!isActive) {
            onIsActive()
          }
        }
      }
      prevHref.current = href
    }

    if (isActive !== props.prevIsActive && !isActive) {
      changeToInitialMeta()
    }

    const clearCurrentActiveMeta = () => {
      setMeta(defaultMeta)
      setShouldUpdateToInitialMeta(false)
    }

    if (
      props.prevShouldUpdateToInitialMeta !== shouldUpdateToInitialMeta &&
      shouldUpdateToInitialMeta
    ) {
      clearCurrentActiveMeta()
    }
  }, [
    isActive,
    location,
    shareId,
    itemId,
    onIsNotActive,
    onIsActive,
    // shouldUpdateToInitialMeta,
    props.prevIsActive,
    props.prevShouldUpdateToInitialMeta,
  ])

  const getMetaContent = id => {
    const query = document.querySelector(`meta[property='${id}']`)

    return query ? query.getAttribute("content") : ""
  }

  const changeToInitialMeta = () => {
    setMeta(initialMeta)
    setShouldUpdateToInitialMeta(true)
  }

  const changeMeta = () => {
    const imageSrc = getMetaContent("og:image")
    const title = getMetaContent("og:title")
    const description = getMetaContent("og:description")

    setMeta({
      title: metaTitle,
      imageSrc: metaImageSrc,
      description: metaDescription,
    })

    setInitialMeta({
      title,
      imageSrc,
      description,
    })
  }

  useEffect(() => {
    const changeToActiveStateFromUrl = () => {
      const {
        location: { hash },
      } = window

      if (hash === `${shareId}${itemId}`) {
        changeMeta()
        onIsActive()
      }
    }

    changeToActiveStateFromUrl()
  }, [])

  const renderHelmet = () => {
    const { title, imageSrc, description } = meta

    const isSomeMetaParamPassed = metaTitle || metaImageSrc || metaDescription

    if (!isSomeMetaParamPassed || (!shouldUpdateToInitialMeta && !isActive)) {
      return null
    }

    const metaData = []

    if (title) {
      metaData.push({
        property: "og:title",
        content: title,
      })
    }

    if (imageSrc) {
      metaData.push({
        property: "og:image",
        content: imageSrc,
      })
      metaData.push({
        property: "twitter:image",
        content: imageSrc,
      })
    }

    if (description) {
      metaData.push({
        property: "og:description",
        content: description,
      })
    }

    if (!metaData.length) return null

    return <Helmet meta={metaData} />
  }

  return (
    <div className="ShareWrapper">
      {renderHelmet()}
      {children}
    </div>
  )
}

export default ShareWrapper
