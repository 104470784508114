import React from "react"
import BlogPostHeader from "./BlogPostHeader.component"

const BlogPostHeaderContainer = ({
  title = "",
  categories = [{}],
  excerpt = "",
  featured_media = {},
}) => {
  return (
    <BlogPostHeader
      categories={categories}
      featuredMediaSrc={featured_media?.source_url || ""}
      title={title}
      description={excerpt}
    />
  )
}

export default BlogPostHeaderContainer
