import React, { useEffect } from "react"
import { Animate as AnimateOverlay, useAnimate } from "react-simple-animate"

function AnimateChild({ play, start, end, duration, delay, easing, children }) {
  const { style, play: playFunc } = useAnimate({
    play,
    start,
    end,
    duration,
    delay,
    easing,
  })

  useEffect(() => {
    playFunc(play)
  }, [play, playFunc])

  if (
    typeof children === "string" ||
    typeof children === "number" ||
    children === null
  ) {
    return children
  }

  const {
    type,
    props,
    props: { style: childStyle, children: child },
  } = children

  // If child is DOM element
  if (typeof type === "string") {
    const Tag = type

    if (!child || !child.length) {
      return <Tag {...props} style={{ ...childStyle, ...style }} />
    }

    return (
      <Tag {...props} style={{ ...childStyle, ...style }}>
        {child}
      </Tag>
    )
  }

  return (
    <div>
      {React.cloneElement(children, { style: { ...childStyle, ...style } })}
    </div>
  )
}

function Animate({
  refProp,
  play,
  start,
  end,
  duration,
  delay,
  easing,
  children: child,
}) {
  return React.Children.map(child, children => (
    <AnimateChild
      refProp={refProp}
      play={play}
      start={start}
      end={end}
      duration={duration}
      delay={delay}
      easing={easing}
      children={children}
    />
  ))
}

const Transition = props => {
  const {
    isAnimationEnabled = false,
    delay = "",
    duration = "",
    fadeInIsEnabled = false,
    movementIsEnabled = false,
    movementSide = "",
    movementStartPosition = "",
    easing = "",
    play = false,
    overlayIsEnabled = false,
    overlaySide = "",
    overlayColor = "",

    children,
    refProp,
  } = props

  const renderTransition = () => {
    if (!isAnimationEnabled) {
      return children
    }

    const start = {}
    const end = {}

    if (fadeInIsEnabled) {
      //Was put to one so the animations doesnt start (because for now the animation does not work correctly)
      start.opacity = 1
      end.opacity = 1
    }

    if (movementIsEnabled) {
      start.transform =
        movementSide === "left" || movementSide === "right"
          ? `translateX(${
              movementSide === "right"
                ? movementStartPosition
                : `-${movementStartPosition}`
            })`
          : `translateY(${
              movementSide === "bottom"
                ? movementStartPosition
                : `-${movementStartPosition}`
            })`
      end.transform = "translate(0)"
    }

    return (
      <Animate
        refProp={refProp}
        play={play}
        start={start}
        end={end}
        duration={duration}
        delay={delay}
        easeType={easing}
      >
        {children}
      </Animate>
    )
  }

  const renderOverlay = () => {
    const start = { backgroundColor: overlayColor }
    const end = { backgroundColor: overlayColor }

    if (overlaySide === "top" || overlaySide === "bottom") end.height = 0
    if (overlaySide === "left" || overlaySide === "right") end.width = 0

    return (
      <div className={`OverlayTransition ${overlaySide}`}>
        <AnimateOverlay
          play={play}
          start={start}
          end={end}
          duration={duration}
          delay={delay}
          easeType={easing}
        />
      </div>
    )
  }

  const renderWithOverlay = () => {
    return (
      <div className="OverlayTransitionBlock" ref={refProp}>
        {renderOverlay()}
        {renderTransition()}
      </div>
    )
  }

  if (overlayIsEnabled) return renderWithOverlay()

  return renderTransition()
}

export default Transition
