import React from "react"
import { useSharedContext } from "Src/contexts/SharedContext"
import FooterRegion from "./FooterRegion.component"

const FooterRegionContainer = props => {
  const { pathName } = useSharedContext()

  return <FooterRegion pathName={pathName} {...props} />
}

export default FooterRegionContainer
