import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import BlockTransition from "Components/BlockTransition"
import { useLocationFinderContext } from "Src/contexts/LocationFinderContext"
import { validateZipCode } from "Utils/Validation"
import { getLanguage } from "Utils/Language"
import "./LocationFinderSubmit.style.scss"
import { useSharedContext } from "Src/contexts/SharedContext"

const LocationFinderSubmit = ({
  inputPlaceholder = "",
  submitButtonText = "",
  t,
  elemsTransition,
}) => {
  const [errorNoZipCode, setErrorNoZipCode] = useState(false)
  const { suggestionCode } = useSharedContext()

  const [errorZipCodeNotValid, setErrorZipCodeNotValid] = useState(false)

  const { fetchStore, zipCode, setZipCode, onChange, setIsStore } =
    useLocationFinderContext()

  const handleSubmitClick = async () => {
    if (!zipCode) return setErrorNoZipCode(true)

    const { language } = getLanguage()
    if (!validateZipCode(zipCode, language, suggestionCode)) {
      setZipCode("")
      setErrorZipCodeNotValid(true)
      return
    }

    window.scrollTo({ top: 0, behavior: "smooth" })

    setIsStore(false)
    await fetchStore(t)

    setErrorNoZipCode(false)
    setErrorZipCodeNotValid(false)
  }

  const renderInput = () => {
    if (!inputPlaceholder) return null

    const isError = errorZipCodeNotValid || errorNoZipCode ? "isError" : ""
    const enterKey = 13

    return (
      <input
        className={`ZipCodeInput ${isError}`}
        placeholder={inputPlaceholder}
        value={zipCode}
        onChange={onChange}
        onKeyDown={e => e.keyCode === enterKey && handleSubmitClick()}
      />
    )
  }

  const renderSubmitButton = () => {
    if (!submitButtonText) return null

    return (
      <button
        className="SubmitButton PrimaryButton"
        onClick={handleSubmitClick}
      >
        {submitButtonText}
      </button>
    )
  }

  const renderError = () => {
    if (errorZipCodeNotValid) {
      return (
        <>
          <i class="fa fa-times-circle danger"></i>
          <span className="ErrorMessage">
            {t("locationFinder.validationError")}
          </span>
        </>
      )
    }

    if (errorNoZipCode) {
      return (
        <>
          <i class="fa fa-times-circle danger"></i>
          <span className="ErrorMessage">{t("locationFinder.enterZipCode")}</span>
        </>
      )
    }

    if (zipCode && !errorNoZipCode) {
      return (
        <>
          <i class="fa fa-check-circle success"></i>
        </>
      )
    }

    return null
  }

  return (
    <BlockTransition elemsTransition={elemsTransition}>
      <div className="LocationFinderSubmit">
        {renderInput()}
        {renderError()}
        {renderSubmitButton()}
        
      </div>
    </BlockTransition>
  )
}

export default withTranslation()(LocationFinderSubmit)
