import React, { useState, useEffect } from "react"
import { createReactFromNode } from "Utils/ReactFromNode"
import { removeHtmlTags } from "Utils/Helpers"

import LocationFinderSubmit from "./LocationFinderSubmit.component"

const LocationFinderSubmitContainer = ({ node, elemsTransition = [] }) => {
  const [props, setProps] = useState({})

  useEffect(() => {
    const createProps = () => {
      const { children } = node

      if (!children.length) return null

      const newProps = children.reduce(
        (acc, { children, attribs, attribs: { class: className } }) => {
          if (className === "ZipCodeInput") {
            const { placeholder } = attribs
            acc.inputPlaceholder = removeHtmlTags(placeholder)
          } else {
            acc.submitButtonText = createReactFromNode(children)
          }
          return acc
        },
        {},
      )

      setProps(newProps)
    }

    createProps()
  }, [node])

  return <LocationFinderSubmit {...props} elemsTransition={elemsTransition} />
}

export default LocationFinderSubmitContainer
