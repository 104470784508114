import React from "react"
import { navigate } from "gatsby"
import { getLanguage } from "Utils/Language"
import "./BlogPostCategories.style.scss"
import ProgressiveImage from "Components/ProgressiveImg"
import { getPreviewImageSrc } from "Utils/Helpers"

const BlogPostCategories = ({
  categoriesMap,
  activePageCategory,
  title = "",
}) => {
  const getIdByTitle = (categoriesMap, titleToFind) => {
    const foundCategory = categoriesMap.find(
      category => category.title === titleToFind,
    )
    return foundCategory ? foundCategory.id : 0
  }

  const handleCategoryClick = (e, categoryName) => {
    const { change } = activePageCategory
    const { url } = getLanguage()
    const pathName = `${url}/blog/`
    e.preventDefault()
    const activeId = getIdByTitle(categoriesMap, categoryName)
    change({ name: categoryName, id: activeId })
    navigate(pathName)
  }

  const renderCategoryTitle = title => {
    if (!title) return null

    return <p className="CategoryTitle">{title}</p>
  }

  const renderCategoryImage = imageSrc => {
    if (!imageSrc) return null

    return (
      <div className="CategoryImageWrapper">
        <ProgressiveImage
          src={getPreviewImageSrc(imageSrc)}
          largeSrc={imageSrc}
          className="CategoryImage"
          alt="category"
        />
      </div>
    )
  }

  const renderSingleCategory = ({ title = "", imageSrc = "" }, index) => (
    <button
      key={index}
      className="NoButtonStyle Category"
      onClick={e => handleCategoryClick(e, title)}
    >
      {renderCategoryImage(imageSrc)}
      {renderCategoryTitle(title)}
    </button>
  )

  const renderTitle = () => {
    if (!title) return null

    return <h2 className="BlockTitle">{title}</h2>
  }

  if (!categoriesMap.length) return null

  return (
    <div className="BlogPostCategories">
      {renderTitle()}
      {categoriesMap.map(renderSingleCategory)}
    </div>
  )
}

export default BlogPostCategories
