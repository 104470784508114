import React from "react"
import ShareButtonGroup from "Components/ShareButtonGroup"
import { htmlToReact } from "Utils/HtmlParser"

import "./BlogPostShare.style.scss"

const BlogPostShare = ({
  title = "",
  metaTitle = "",
  metaDescription = "",
  metaFeaturedMediaSrc = "",

  ...props
}) => {
  return (
    <div className="BlogPostShare">
      <div className="Content">
        <p className="ContentTitle">{htmlToReact(title)}</p>
      </div>
      <ShareButtonGroup
        title={metaTitle}
        description={metaDescription}
        featuredMediaSrc={metaFeaturedMediaSrc}
        {...props}
      />
    </div>
  )
}

export default BlogPostShare
