import React, { useState, useEffect } from "react"
import { Link as GatsbyLink } from "gatsby"
import { getLanguage } from "Utils/Language"
import {
  getUrlWithoutTrailingSlash,
  getUrlWithTrailingSlash,
  getUTMDataString,
  isFilePath,
} from "Utils/Helpers"

export const Link = ({
  to,
  className,
  pathnameurl,
  isExternal: isExternalProp = false,
  onClick,
  children = [],
}) => {
  const [dyobUTMData, setDyobUTMData] = useState("")
  const [UTMData, setUTMData] = useState("")

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const cid = urlParams.get("cid")
    const blog = urlParams.get("blog")
    const utm_source = urlParams.get("utm_source")
    const utm_medium = urlParams.get("utm_medium")
    const utm_campaign = urlParams.get("utm_campaign")

    if ((cid && utm_source && utm_medium && utm_campaign) || blog) {
      setUTMData(
        `?cid=${cid}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`,
      )
    }

    if (cid && !utm_source && !utm_medium && !utm_campaign) {
      setUTMData(`?cid=${cid}`)
    }
  }, [])

  useEffect(() => {
    if (to && to.includes("design.")) setDyobUTMData(getUTMDataString())
  }, [to])

  const setTrailingSlashInTheEnd = url =>
    isFilePath(url)
      ? getUrlWithoutTrailingSlash(url)
      : getUrlWithTrailingSlash(url)

  const getToPath = () => {
    const { url } = getLanguage()

    if (to === "/") return setTrailingSlashInTheEnd(url)
    if (to.includes("#")) return getUrlWithoutTrailingSlash(to)

    return setTrailingSlashInTheEnd(to)
  }

  const isExternal = isExternalProp || (to && !/^\/(?!\/)/.test(to))

  const linkProps = {
    ...Object.entries({ to, pathnameurl, isExternal }).reduce(
      (acc, [key, value]) => {
        if (key === "pathnameurl" || key === "isExternal") return acc

        if (isExternal && key === "to") {
          acc.href = value + dyobUTMData
          return acc
        }

        acc[key] = value
        return acc
      },
      {},
    ),
  }

  if (isExternal) {
    return (
      <a {...linkProps} className={className} onClick={onClick}>
        {children}
      </a>
    )
  }

  return (
    <GatsbyLink
      {...linkProps}
      to={getToPath() + UTMData}
      className={className}
      onClick={onClick}
    >
      {children}
    </GatsbyLink>
  )
}

export default Link
