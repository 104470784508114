import React from "react"
import HtmlToReact, { Parser as HtmlToReactParser } from "html-to-react"

import DidYouKnowSlider from "Components/DidYouKnowSlider"
import ContentAndImageCarousel from "Components/ContentAndImageCarousel"
import BeforeAndAfterPictureSlider from "Components/BeforeAndAfterPictureSlider"
import BathroomSolutions from "Components/BathroomSolutions"
import Video from "Components/Video"
import TagsBlock from "Components/TagsBlock"
import LocationFinderSubmit from "Components/LocationFinderSubmit"
import Link from "Components/Link"
import BookingConsultationBlockButton from "Components/BookingConsultationBlockButton"
import ColorSelectorTool from "Components/ColorSelectorTool"
import {
  getPreviewImageSrc,
  getStyleObjectFromString,
  isFilePath,
  loadLargeImage,
} from "Utils/Helpers"
import { getLanguage } from "Utils/Language"
import ClientStorySlider from "Components/ClientStorySlider/ClientStorySlider.component"
import TagsWrapper from "Components/TagsWrapper"
import CategoriesSelector from "Components/CategoriesSelector"
import InstagramFeed from "Components/InstagramFeed"
import GalleryFeaturesListing from "Components/GalleryFeaturesListing"
import ClientStoriesCarouselBlock from "Components/ClientStoriesCarouselBlock"
import ClientStoriesTilesBlock from "Components/ClientStoriesTilesBlock"
import ComparisonBlockTable from "Components/ComparisonBlockTable"
import ProgressiveImage from "Components/ProgressiveImg"
import BlockTransition from "Components/BlockTransition"
import EmbeddedVideo from "Components/EmbeddedVideo"

// Blog
import BlogOlderPosts from "Components/BlogOlderPosts"
import BlogLatestPosts from "Components/BlogLatestPosts"
import BlogSecondaryPosts from "Components/BlogSecondaryPosts"

// Blog Post
import BlogPostHeader from "Components/BlogPostHeader"
import BlogPostSearchInput from "Components/BlogPostSearchInput"
import BlogPostLatestPosts from "Components/BlogPostLatestPosts"
import BlogPostAuthor from "Components/BlogPostAuthor"
import BlogPostCategories from "Components/BlogPostCategories"
import BlogPostShare from "Components/BlogPostShare"
import BlogPostMostPopularPosts from "Components/BlogPostMostPopularPosts"
import BlogPostTitleSection from "Components/BlogPostTitleSection"
import BlogPostFeaturedListingSlider from "Components/BlogPostFeaturedListingSlider"

import { parseJson } from "Utils/Validation"
import TwoPassRendering from "Components/TwoPassRendering"
import LocaleSelectorSelect from "Components/LocaleSelectorSelect"
import { FAQBlock, FAQSection } from "../components/FAQ"

const getDataFromNode = ({
  children: [
    {
      attribs: { data },
    },
  ],
}) => parseJson(data, {})
const getPropsFromNode = ({ attribs: { data } }) => parseJson(data, {})

export const widgetMap = [
  {
    criteria: "isLikeChecklist",
    component: node => <DidYouKnowSlider value="microcopy" node={node} />,
  },
  {
    criteria: "ClientStory-Slider",
    component: node => <ClientStorySlider node={node} />,
  },
  {
    criteria: "HomepageContentAndImageCarousel-Slider",
    component: node => <ContentAndImageCarousel node={node} />,
  },
  {
    criteria: "HomepageBeforeAndAfterPictureSlider-Slider",
    component: node => <BeforeAndAfterPictureSlider node={node} />,
  },
  {
    criteria: "HomepageFAQA",
    component: node => <FAQSection node={node} />,
  },
  {
    criteria: "VideoBlock",
    component: node => <Video {...getPropsFromNode(node)} />,
  },
  {
    criteria: "HomepageTagsBlock-Content",
    component: (node, children, uuid, props) => (
      <TagsBlock node={node} {...props} />
    ),
  },
  {
    criteria: "StoreFinderPreview-FindStore",
    component: node => <LocationFinderSubmit node={node} />,
  },
  {
    criteria: "BathroomSolutionsWrapper",
    component: (node, children, uuid) => (
      <BathroomSolutions node={node} uuid={uuid}>
        {children}
      </BathroomSolutions>
    ),
  },
  {
    criteria: "BookingConsultationBlockButton",
    component: node => <BookingConsultationBlockButton node={node} />,
  },
  {
    criteria: "ColorSelectorTool",
    component: node => <ColorSelectorTool node={node} />,
  },
  {
    criteria: "FAQBlocks-Container",
    component: node => <FAQBlock node={node} />,
  },
  {
    criteria: "TagsWrapper",
    component: (node, children) => (
      <TagsWrapper node={node}>{children}</TagsWrapper>
    ),
  },
  {
    criteria: "wp-block-bathfitter-tags-selector HomePage",
    component: (node, children, uuid, { posts }) => (
      <CategoriesSelector {...getDataFromNode(node)} posts={posts} />
    ),
  },
  {
    criteria: "wp-block-bathfitter-blog-latest-posts BlogLatestPosts",
    component: (node, children, uuid, { posts }) => (
      <BlogLatestPosts {...getPropsFromNode(node)} posts={posts} />
    ),
  },
  {
    criteria: "wp-block-bathfitter-blog-older-posts BlogOlderPosts",
    component: (node, children, uuid, { posts }) => (
      <BlogOlderPosts {...getPropsFromNode(node)} posts={posts} />
    ),
  },
  {
    criteria: "wp-block-bathfitter-blog-secondary-posts BlogSecondaryPosts",
    component: (node, children, uuid, { posts }) => (
      <BlogSecondaryPosts {...getPropsFromNode(node)} posts={posts} />
    ),
  },
  {
    criteria: "wp-block-bathfitter-instagram-feed InstagramFeed",
    component: node => <InstagramFeed {...getPropsFromNode(node)} />,
  },
  {
    criteria: "wp-block-bathfitter-blog-post-header BlogPostHeader",
    component: (node, children, uuid, { post }) => <BlogPostHeader {...post} />,
  },
  {
    criteria: "wp-block-bathfitter-blog-post-search BlogPostSearch",
    component: node => <BlogPostSearchInput {...getPropsFromNode(node)} />,
  },
  {
    criteria: "wp-block-bathfitter-blog-post-latest-posts BlogPostLatestPosts",
    component: (node, children, uuid, { post }) => (
      <BlogPostLatestPosts {...getPropsFromNode(node)} posts={post} />
    ),
  },
  {
    criteria: "wp-block-bathfitter-blog-post-author BlogPostAuthor",
    component: (node, children, uuid, { page: { author } }) => (
      <BlogPostAuthor {...getPropsFromNode(node)} author={author} />
    ),
  },
  {
    criteria: "wp-block-bathfitter-blog-post-categories BlogPostCategories",
    component: (node, children, uuid, { posts }) => (
      <BlogPostCategories {...getPropsFromNode(node)} posts={posts} />
    ),
  },
  {
    criteria: "wp-block-bathfitter-blog-post-share BlogPostShare",
    component: (
      node,
      children,
      uuid,
      { post: { title, excerpt, post_media_source } = {} },
    ) => (
      <BlogPostShare
        {...getPropsFromNode(node)}
        metaTitle={title}
        metaDescription={excerpt}
        metaFeaturedMediaSrc={post_media_source}
      />
    ),
  },
  {
    criteria: "wp-block-bathfitter-blog-post-older-posts BlogPostOlderPosts",
    component: (node, children, uuid, { posts }) => (
      <BlogOlderPosts
        {...getPropsFromNode(node)}
        posts={posts}
        prevBlocksShowedPostsCount={4}
      />
    ),
  },
  {
    criteria:
      "wp-block-bathfitter-blog-post-most-popular-posts BlogPostMostPopularPosts",
    component: (node, children, uuid, { posts }) => (
      <BlogPostMostPopularPosts {...getPropsFromNode(node)} posts={posts} />
    ),
  },
  {
    criteria: "wp-block-bathfitter-blog-post-title BlogPostTitle",
    component: (node, children, uuid, { posts }) => {
      const [{ featured_media }] = posts || [{}]
      const { title, yoast_meta } = posts || {}
      const { source_url } = featured_media || {}
      const { metadesc } = yoast_meta || {}

      return (
        <BlogPostTitleSection
          {...getPropsFromNode(node)}
          metaDescription={metadesc}
          metaTitle={title}
          metaImage={source_url || ""}
        />
      )
    },
  },
  {
    criteria:
      "wp-block-bathfitter-blog-post-featured-listing-slider BlogPostFeaturedListingSlider",
    component: (node, children, uuid, { posts }) => (
      <BlogPostFeaturedListingSlider
        {...getPropsFromNode(node)}
        posts={posts}
      />
    ),
  },
  {
    criteria: "GalleryFeaturesListing-Content",
    component: (node, children, uuid, props) => (
      <GalleryFeaturesListing node={node} {...props} />
    ),
  },
  {
    criteria: "ClientStoriesTilesBlock",
    component: (node, children, uuid, props) => (
      <ClientStoriesTilesBlock node={node} {...props} />
    ),
  },
  {
    criteria: "ClientStoriesCarouselBlock",
    component: (node, children, uuid, props) => (
      <ClientStoriesCarouselBlock node={node} {...props} />
    ),
  },
  {
    criteria: "BlockTransition",
    component: (node, children) => (
      <BlockTransition {...getPropsFromNode(node)}>{children}</BlockTransition>
    ),
  },
  {
    criteria: "ComparisonBlockTable",
    component: node => <ComparisonBlockTable {...getPropsFromNode(node)} />,
  },
  {
    criteria: "EmbeddedVideo",
    component: (node, children) => (
      <EmbeddedVideo {...getPropsFromNode(node)}>{children}</EmbeddedVideo>
    ),
  },
  {
    criteria: "wp-locale-selector-select",
    component: (node, children) => (
      <LocaleSelectorSelect {...getPropsFromNode(node)}>
        {children}
      </LocaleSelectorSelect>
    ),
  },
]

const processLinks = (node, children) => {
  const baseUrl = process.env.GATSBY_WORDPRESS_URL
  const {
    attribs,
    attribs: { href },
  } = node

  const attributes = Object.keys(attribs).length
    ? Object.keys(attribs).reduce((acc, key) => {
        if (key !== "href" && key !== "class") acc[key] = attribs[key]
        if (key === "class") acc.className = attribs[key]

        return acc
      }, {})
    : {}

  const to =
    !isFilePath(href) && href.includes(baseUrl)
      ? href.replace(baseUrl, "")
      : href

  // Functionality to transform links for Quick Links Widget
  // Adds locale code in front of the search link i.e.
  // /search?s=test => /us-en/search?s=test
  if (
    attributes?.className?.includes("sw-bathfitter-widget-quick-links-item")
  ) {
    const { url } = getLanguage()
    const localizedTo = `${url}${to}`

    return (
      <Link to={localizedTo} {...attributes}>
        {children}
      </Link>
    )
  }

  return (
    <Link to={to} {...attributes}>
      {children}
    </Link>
  )
}

const htmlParser = (
  htmlInput,
  uuid = "",
  props = {},
  disableImagePreview = false,
) => {
  const htmlToReactParser = new HtmlToReactParser()
  const isValidNode = () => true
  const { processDefaultNode } = new HtmlToReact.ProcessNodeDefinitions(React)

  const processingInstructions = [
    {
      shouldProcessNode({ attribs }) {
        return (
          attribs &&
          attribs.href &&
          !attribs.href.includes("/succursale/") &&
          !attribs.href.includes("/location/")
        )
      },
      processNode(node, children) {
        return processLinks(node, children)
      },
    },
    {
      shouldProcessNode({ attribs }) {
        return !!widgetMap.find(
          ({ criteria }) => attribs && attribs.class === criteria,
        )
      },
      processNode(node, children) {
        const { attribs } = node
        const { component } = widgetMap.find(
          ({ criteria }) => attribs.class === criteria,
        )

        return component(node, children, uuid, props)
      },
    },
    {
      shouldProcessNode(node) {
        return (
          node.type === "tag" && node.name === "img" && !disableImagePreview
        )
      },
      processNode(node) {
        const { attribs } = node
        const { src, style } = attribs

        if (style) {
          attribs.style = getStyleObjectFromString(style)
        }

        if (src) {
          attribs.largeSrc = src
          const previewImageSrc = getPreviewImageSrc(src)

          if (previewImageSrc !== src) {
            attribs.src = previewImageSrc

            if (attribs.class) {
              attribs.class += " ImagePreview"
            } else {
              attribs.class = "ImagePreview"
            }
          }

          attribs.onLoad = loadLargeImage
          attribs.onError = loadLargeImage
        }

        return (
          <TwoPassRendering>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img {...attribs} />
          </TwoPassRendering>
        )
      },
    },
    {
      shouldProcessNode() {
        return true
      },
      processNode: processDefaultNode,
    },
  ]

  const htmlInputReplaced =
    typeof htmlInput == "string"
      ? htmlInput.replace(/&lt;/g, "<")
      : htmlInput.rendered.replace(/&lt;/g, "<")
  const reactComponent = htmlToReactParser.parseWithInstructions(
    htmlInputReplaced,
    isValidNode,
    processingInstructions,
  )

  return reactComponent
}

export const htmlToReact = htmlInput => {
  const isValidNode = () => true
  const htmlToReactParser = new HtmlToReactParser()
  const { processDefaultNode } = new HtmlToReact.ProcessNodeDefinitions(React)

  const processingInstructions = [
    {
      shouldProcessNode({ attribs }) {
        return attribs && attribs.href
      },
      processNode(node, children) {
        return processLinks(node, children)
      },
    },
    {
      shouldProcessNode(node) {
        return node.type === "tag" && node.name === "img"
      },
      processNode(node) {
        const {
          attribs: { src, style, ...restAttribs },
        } = node

        if (style) {
          restAttribs.style = getStyleObjectFromString(style)
        }

        return (
          <ProgressiveImage
            src={getPreviewImageSrc(src)}
            largeSrc={src}
            {...restAttribs}
          />
        )
      },
    },
    {
      shouldProcessNode() {
        return true
      },
      processNode: processDefaultNode,
    },
  ]

  const htmlInputReplaced =
    typeof htmlInput == "string"
      ? htmlInput.replace(/&lt;/g, "<")
      : htmlInput.rendered.replace(/&lt;/g, "<")
  const reactComponent = htmlToReactParser.parseWithInstructions(
    htmlInputReplaced,
    isValidNode,
    processingInstructions,
  )

  return reactComponent
}

export default htmlParser
