import { useState, useEffect } from "react"
import { parseJson } from "Utils/Validation"

const TagsWrapper = ({ children, activePageTag }) => {
  const [activeChildren, setActiveChildren] = useState(null)

  useEffect(() => {
    const { id: activeId } = activePageTag
    const foundChild = children.find(
      ({ props: { data } }) => parseJson(data, {}).id === activeId,
    )
    setActiveChildren(foundChild)
  }, [children, activePageTag])

  return activeChildren
}

export default TagsWrapper
