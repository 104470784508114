import React from "react"
import ShareButton from "Components/ShareButton"
import { isBrowser } from "Utils/Helpers"
import "./ShareButtonGroup.style.scss"

const ShareButtonGroup = ({
  url: propsUrl = "",
  title,
  description,
  featuredMediaSrc,
}) => {
  const url = propsUrl || (isBrowser ? window.location.href : "")

  return (
    <div className="ShareButtonGroup">
      <ShareButton type="facebook" url={url} />
      <ShareButton type="twitter" url={url} twitterTitle={title} />
      <ShareButton
        type="pinterest"
        url={url}
        media={featuredMediaSrc}
        description={description}
      />
      <ShareButton
        type="email"
        url={url}
        subject={title}
        body={description}
        media={featuredMediaSrc}
      />
    </div>
  )
}

export default ShareButtonGroup
