import React from "react"
import { useSharedContext } from "Src/contexts/SharedContext"
import LocaleSelectorSelect from "./LocaleSelectorSelect.component"

const LocaleSelectorSelectContainer = props => {
  const { navigateToTranslation } = useSharedContext()

  return (
    <LocaleSelectorSelect
      navigateToTranslation={navigateToTranslation}
      {...props}
    />
  )
}

export default LocaleSelectorSelectContainer
