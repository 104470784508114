/* eslint-disable global-require */
import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { urlLanguageLookup, languages, languageCodes } from "Utils/Language"
import translationUS from "Locales/en/translations.json"
import translationES from "Locales/es/translations.json"
import translationCA from "Locales/ca/translations.json"
import translationQCEN from "Locales/qcen/translations.json"
import translationQCFR from "Locales/qc/translations.json"

const { us_en, us_es, ca_en, qc_en, qc_fr } = languages

const languageDetector = new LanguageDetector()

languageDetector.addDetector({
  name: "languageDetector",
  // Fired on initial page load
  lookup() {
    const { code } = urlLanguageLookup()

    return code
  },
})

i18next.use(languageDetector).init({
  fallbackLng: "us_en",
  detection: {
    order: ["languageDetector"],
  },
  resources: {
    us_en: {
      options: us_en,
      translations: translationUS,
    },
    us_es: {
      options: us_es,
      translations: translationES,
    },
    ca_en: {
      options: ca_en,
      translations: translationCA,
    },
    qc_en: {
      options: qc_en,
      translations: translationQCEN,
    },
    qc_fr: {
      options: qc_fr,
      translations: translationQCFR,
    },
  },
  ns: ["translations", "options"],
  defaultNS: "translations",
  returnObjects: true,
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
})

i18next.languages = languageCodes

export default i18next
