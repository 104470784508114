import React from "react"
import { getLanguage } from "Utils/Language"
import { useSharedContext } from "Src/contexts/SharedContext"
import BlogPostFeaturedListingSlider from "./BlogPostFeaturedListingSlider.component"

const BlogPostFeaturedListingSliderContainer = ({
  posts = [],
  badgeImageSrc = "",
  postFilterTag = "",
  buttonName = "",
  buttonId = "",
  backgroundColor = "",
}) => {
  const { activePageCategory } = useSharedContext()
  const { url } = getLanguage()
  const currentLanguage = url.slice(1)

  const getSliderMap = () => {
    return posts.reduce(
      (
        acc,
        { categories, tags, featured_media, title, excerpt = "", slug, lang },
      ) => {
        const isFilterTag =
          tags &&
          tags.find(
            ({ tag_name }) =>
              tag_name.toLowerCase() === postFilterTag.toLowerCase(),
          )
        const showAll =
          !activePageCategory.name || activePageCategory?.name?.trim() === ""

        const hasActiveCategory =
          showAll ||
          (categories &&
            categories.find(
              ({ category_name }) =>
                category_name.toLowerCase() ===
                activePageCategory?.name?.toLowerCase(),
            ))
        const hasCorrectLanguage =
          lang && lang.toLowerCase() === currentLanguage.toLowerCase()

        if (isFilterTag && hasActiveCategory && hasCorrectLanguage) {
          const { source_url: imageUrl = "" } = featured_media || {}
          acc.push({
            imageUrl,
            categories,
            title,
            postHref: `${url}/blog/${slug}`,
            description: excerpt,
          })
        }

        return acc
      },
      [],
    )
  }

  const sliderMap = getSliderMap()

  if (!sliderMap.length) return null

  return (
    <BlogPostFeaturedListingSlider
      sliderMap={sliderMap}
      badgeImageSrc={badgeImageSrc}
      buttonName={buttonName}
      buttonId={buttonId}
      backgroundColor={backgroundColor}
    />
  )
}

export default BlogPostFeaturedListingSliderContainer
