import React from "react"
import Link from "Components/Link"
import { BlogPostCardCategories } from "Components/BlogPostCard"
import { getLanguage } from "Utils/Language"
import { htmlToReact } from "Utils/HtmlParser"
import "./BlogPostCard.style.scss"
import ProgressiveImage from "Components/ProgressiveImg"
import { getPreviewImageSrc } from "Utils/Helpers"

const BlogPostCard = ({
  imageSrc = "",
  title = "",
  categories = [],
  slug,
  imageStyle = {},
  customRenderAfterImage,
}) => {
  const renderImage = () => {
    if (!imageSrc) return null

    const cleanedImageSrc = imageSrc.replace(/https:\/\/[^\s]*\.net/g, "")

    return (
      <div className="BlogPostCardImageWrapper" style={imageStyle}>
        <ProgressiveImage
          src={`${getPreviewImageSrc(cleanedImageSrc)}`}
          largeSrc={`${cleanedImageSrc}`}
          className="BlogPostCardImage"
          alt="Post"
        />
      </div>
    )
  }

  const renderTitle = () => {
    if (!title) return null

    return <h3 className="BlogPostCardTitle">{htmlToReact(title)}</h3>
  }

  const renderCategories = () => {
    return <BlogPostCardCategories categories={categories} />
  }

  const { url } = getLanguage()
  const to = `${url}/blog/${slug}`

  return (
    <Link className="NoButtonStyle BlogPostCard" to={to}>
      {renderImage()}
      <div className="BlogPostCardContent">
        {customRenderAfterImage()}
        {renderCategories()}
        {renderTitle()}
      </div>
    </Link>
  )
}

export default BlogPostCard
