/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/static/css/jquery.tipso.min.css"
import "./src/static/css/datepicker.min.css"
import "./src/styles/main.scss"
import Provider from "Src/contexts/ContextProvider"

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = Provider
