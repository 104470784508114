import { useContext, useEffect, useState } from "react"
import { CarouselContext } from "pure-react-carousel"

export const ActiveSlide = ({
  onSlideChange,
  children,
  currentSlide: propsCurrentSlide,
}) => {
  const carouselContext = useContext(CarouselContext)
  const {
    state: { currentSlide },
    setStoreState,
  } = carouselContext
  const [, setCurrentSlide] = useState(currentSlide)

  useEffect(() => {
    if (currentSlide !== propsCurrentSlide) {
      setStoreState({ currentSlide: propsCurrentSlide })
    }
  }, [currentSlide, propsCurrentSlide, setStoreState])

  useEffect(() => {
    function onChange() {
      const {
        state: { currentSlide },
      } = carouselContext
      setCurrentSlide(currentSlide)
      onSlideChange(currentSlide)
    }

    const { subscribe, unsubscribe } = carouselContext
    subscribe(onChange)
    return () => unsubscribe(onChange)
  }, [onSlideChange, carouselContext])

  return children
}

export default ActiveSlide
