import React from "react"
import Transition from "./Transition.component"

function TransitionOnView(props) {
  const {
    isAnimationEnabled = false,
    thresholdIsEnabled = false,
    thresholdValue = "0",

    children,
  } = props

  const [isVisible, setVisible] = React.useState(false)
  const domRef = React.useRef()

  React.useEffect(() => {
    if (!isAnimationEnabled) return
    const currentDomRef = domRef.current
    const threshold = thresholdIsEnabled ? { threshold: thresholdValue } : {}
    const observer = new IntersectionObserver(entries => {
      entries.forEach(({ isIntersecting }) => {
        setVisible(isIntersecting)

        if (isVisible || (isIntersecting && !isVisible)) {
          observer.unobserve(currentDomRef)
        }
      })
    }, threshold)
    // observer.observe(currentDomRef) //Generates error

    //return () => observer.unobserve(currentDomRef)
  }, [isVisible, isAnimationEnabled, thresholdIsEnabled, thresholdValue])

  if (!isAnimationEnabled) {
    return children
  }

  return (
    <Transition {...props} play={isVisible} refProp={domRef}>
      {children}
    </Transition>
  )
}

export default TransitionOnView
