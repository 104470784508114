import React from "react"

import { useSharedContext } from "Src/contexts/SharedContext"
import LocaleRedirection from "./LocaleRedirection.component"

const LocaleRedirectionContainer = props => {
  const { pathName } = useSharedContext()

  return <LocaleRedirection pathName={pathName} {...props} />
}

export default LocaleRedirectionContainer
