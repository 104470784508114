import React from "react"
import { createReactFromNode } from "Utils/ReactFromNode"
import Star from "Assets/Star.svg"
import ProgressiveImage from "Components/ProgressiveImg"
import { getStyleObjectFromString } from "Utils/Helpers"
import ClientStoriesTilesBlock from "./ClientStoriesTilesBlock.component"

const MAX_RATING_VALUE = 5

const ClientStoriesTilesBlockContainer = ({ node }) => {
  const getGrandchildValueByClassName = (child, elemClassName = "") => {
    if (!elemClassName) return null
    const matchingChild = child.children.find(
      ({ attribs: { class: className } }) =>
        className.split(" ").includes(elemClassName),
    )

    if (!matchingChild) return null
    return createReactFromNode(matchingChild.children)
  }

  const getAuthorImage = child => {
    const matchingChild = child.children.find(
      ({ attribs: { class: className } }) =>
        className.split(" ").includes("AuthorImage"),
    )

    if (!matchingChild) return null
    return createReactFromNode(matchingChild)
  }

  const starRating = rating => {
    const stars = Number(rating)
    const fullStars = Math.floor(stars)
    const fullStarElements = [...new Array(fullStars)].map((_, index) => (
      <Star className="FullStar" key={`star-${index}`} />
    ))

    return stars === fullStars
      ? fullStarElements
      : [
          ...fullStarElements,
          <svg key={`star-${fullStars}`}>
            <defs>
              <linearGradient id="Gradient">
                <stop offset="0%" stopColor="#007377" />
                <stop offset="50%" stopColor="#007377" />
                <stop offset="50%" stopColor="rgba(0, 0, 0, .15)" />
              </linearGradient>
              <linearGradient id="GradientFeatured">
                <stop offset="0%" stopColor="#FFF" />
                <stop offset="50%" stopColor="#FFF" />
                <stop offset="50%" stopColor="rgba(255, 255, 255, .15)" />
              </linearGradient>
            </defs>
            <Star className="HalfStar" key={`star-${fullStars}`} />
          </svg>,
        ]
  }

  const createChildrenObject = () => {
    const { children } = node
    const featuredItemStyle = getStyleObjectFromString(node?.attribs?.style)

    const listMap = children.reduce((acc, child, index) => {
      const {
        children,
        attribs: { url: hash = "", media = "" },
      } = child

      if (!children.length) return acc
      const image = getAuthorImage(child)
      const title = getGrandchildValueByClassName(child, "Title")
      const author = getGrandchildValueByClassName(child, "Author")
      const content = getGrandchildValueByClassName(child, "Content")
      const location = getGrandchildValueByClassName(child, "Location")
      const rating = getGrandchildValueByClassName(child, "Rating")
      const parsed = Number(rating)

      if (!Number.isNaN(parsed) && parsed >= 1 && parsed <= MAX_RATING_VALUE) {
        const isFeatured = child?.attribs?.class.split(" ").includes("Featured")

        acc.push(
          <div
            className={child.attribs.class}
            style={isFeatured ? featuredItemStyle : {}}
            url={hash}
            media={media}
            key={index}
          >
            {isFeatured && (
              <div className="FeaturedSign">
                <p>Featured</p>
              </div>
            )}
            {!!image && (
              <div className="AuthorImage">
                <ProgressiveImage
                  src={image.props.src}
                  largeSrc={image.props.largeSrc}
                  alt="author"
                />
              </div>
            )}
            <h4 className="Title">{title}</h4>
            <p className="Rating">{starRating(parsed)}</p>
            <p className="Content">{content}</p>
            <p className="Author">{author}</p>
            {location && <p className="Location">{location}</p>}
          </div>,
        )
      }

      return acc
    }, [])

    return { listMap }
  }

  const { listMap } = createChildrenObject()

  return <ClientStoriesTilesBlock listMap={listMap} />
}

export default ClientStoriesTilesBlockContainer
