import React, { useEffect } from "react"

import Header from "Components/Header"
import Footer from "Components/Footer"
import LocaleRedirection from "Components/LocaleRedirection"
import LocaleSelector from "Components/LocaleSelector"
import { withTrans } from "../../i18n/withTrans"

import "../../fonts/fonts.scss"
import "./Layout.style.scss"
import "@fortawesome/fontawesome-free/css/all.min.css"
import { useLocation } from "@reach/router"
import { useSharedContext } from "Src/contexts/SharedContext"

const Layout = props => {
  const { children } = props

  const { setHasPageHeader, setHasPageFooter } = useSharedContext()
  const { key } = useLocation()

  useEffect(() => {
    setHasPageHeader(true)
    setHasPageFooter(true)
  }, [key])

  return (
    <>
      <LocaleSelector />
      <LocaleRedirection />
      <Header location={props} />
      {children}
      <Footer />
      {/* <CookieNotice /> */}
    </>
  )
}

export default withTrans(Layout)
