import React from "react"
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from "@reach/router"

import ShareWrapper from "./ShareWrapper.component"

const ShareWrapperContainer = props => {
  const location = useLocation()

  return <ShareWrapper {...props} location={location} />
}

export default ShareWrapperContainer
