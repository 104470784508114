import i18next from "i18next"
import { isBrowser } from "Utils/Helpers"

export const defaultLanguageLocale = "en"
export const defaultLanguageCode = "us_en"
export const defaultLanguageUrl = "/us-en"

export const languages = {
  us_en: {
    locale: defaultLanguageLocale,
    code: defaultLanguageCode,
    label: "United States (English)",
    url: defaultLanguageUrl,
    base: process.env.GATSBY_FE_BF_URL,
    hreflang: "en-us",
    siteName: "Bath Fitter",
    enabled: true,
  },
  us_es: {
    locale: "es",
    code: "us_es",
    label: "Estados Unidos (Español)",
    url: "/us-es",
    base: process.env.GATSBY_FE_BF_URL,
    hreflang: "es-us",
    siteName: "Bath Fitter",
    enabled: true,
  },
  ca_en: {
    locale: defaultLanguageLocale,
    code: "ca_en",
    label: "Canada (English)",
    url: "/ca-en",
    base: process.env.GATSBY_FE_BF_URL,
    hreflang: "en-ca",
    siteName: "Bath Fitter",
    enabled: true,
  },
  qc_en: {
    locale: defaultLanguageLocale,
    code: "qc_en",
    label: "Quebec (English)",
    url: "/qc-en",
    base: process.env.GATSBY_FE_BM_URL,
    hreflang: "en-ca",
    siteName: "Bain Magique",
    enabled: true,
  },
  qc_fr: {
    locale: "fr",
    code: "qc_fr",
    label: "Québec (Français)",
    url: "/qc-fr",
    base: process.env.GATSBY_FE_BM_URL,
    hreflang: "fr-ca",
    siteName: "Bath Fitter",
    enabled: true,
  },
}

export const languageCodes = [...Object.keys(languages)]

export const getLanguage = () => {
  const { language } = i18next
  const options = i18next.getResourceBundle(language, "options")

  return { ...options, language }
}

export const formatFromWPLanguage = languageCode =>
  languageCode.replace("-", "_")

export const formatToWpLanguage = languageCode => languageCode.replace("_", "-")

export const urlLanguageLookup = locationProp => {
  if (!isBrowser && !locationProp) return defaultLanguageCode

  const currentLocation = locationProp || window.location
  const { 1: pathLanguage } = currentLocation.pathname.split("/")
  const languageCode = formatFromWPLanguage(pathLanguage)

  return languageCodes.includes(languageCode)
    ? languages[languageCode]
    : languages[defaultLanguageCode]
}

export const setLanguage = (lng, cb, locationProp) => {
  const { code } = urlLanguageLookup(locationProp)
  const language = lng || code

  if (language !== i18next.language) {
    i18next.changeLanguage(language, cb)
  }
}
