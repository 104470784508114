import React from "react"
import { useInstagramFeed } from "Src/hooks/useInstagramFeed"
import InstagramFeed from "./InstagramFeed.component"

const InstagramFeedContainer = ({
  microcopy = "",
  blockTitle = "",
  bannerTitle = "",
  hashtag = "",
}) => {
  const { data } = useInstagramFeed()

  return (
    <InstagramFeed
      instaImageMap={data?.insta?.nodes || []}
      microcopy={microcopy}
      blockTitle={blockTitle}
      bannerTitle={bannerTitle}
      hashtag={hashtag}
    />
  )
}

export default InstagramFeedContainer
