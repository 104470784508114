// Function to validate field length and format
let validateFieldLength = (fieldValue, fieldName, language, fieldType, t) => {
  // Check if the field type should be validated
  const skipTypes = ["hidden", "submit", "html", "select-one", "address"]
  if (skipTypes.includes(fieldType)) {
    return ""
  }

  // Check if the field value is empty or not a string
  if (
    !fieldValue ||
    typeof fieldValue !== "string" ||
    fieldValue.trim() === ""
  ) {
    return `${fieldName}${["listselect", "textarea"].includes(fieldType) ? "*" : ""} ${t("NinjaForm.required")}`
  }

  // Trim the field value, except for textarea
  if (fieldType !== "textarea") {
    fieldValue = fieldValue.trim()
  }

  // Specific field type validations
  switch (fieldType) {
    case "phone":
      if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(fieldValue)) {
        return `${t("NinjaForm.invalid")} ${fieldName}.`
      }
      break
    case "email":
      if (
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(fieldValue)
      ) {
        return `${t("NinjaForm.invalid")} ${fieldName}.`
      }
      break
    case "zip":
      const isCanadian = ["/ca-en", "/qc-en", "/qc-fr"].includes(language)
      const isUS = ["/us-en", "/us-es"].includes(language)
      if (
        isCanadian &&
        !/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/.test(fieldValue)
      ) {
        return `${t("NinjaForm.invalid")} ${fieldName}.`
      }
      if (isUS && !/^\d{5}$/.test(fieldValue)) {
        return `${t("NinjaForm.invalid")} ${fieldName}.`
      }
      break
    case "listcheckbox":
    case "checkbox":
      if (fieldValue === null || fieldValue === "") {
        return `${fieldName} ${t("NinjaForm.required")}`
      }
      break
  }

  // Check if the field value is too short
  if (fieldValue.length < 2) {
    return `${fieldName} ${t("NinjaForm.short")}`
  }

  // Universal character acceptance for all fields, with special handling for textarea
  const alphanumericRegex = /^[\p{L}\p{N}\p{P}\p{Z}]+$/u

  if (fieldType === "textarea") {
    // For textarea, replace newlines with a space before testing
    const testValue = fieldValue.replace(/\n/g, " ")
    if (!alphanumericRegex.test(testValue)) {
      return `${fieldName} ${t("NinjaForm.alphanumeric")}`
    }
  } else {
    if (!alphanumericRegex.test(fieldValue)) {
      return `${fieldName} ${t("NinjaForm.alphanumeric")}`
    }
  }

  // If all checks pass, return an empty string (no error)
  return ""
}

// Exporting the validation function
export { validateFieldLength }
