import React, { useState, useRef, useEffect } from "react"
import {
  CarouselProvider,
  Slider,
  Slide,
  DotGroup,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { createReactFromNode } from "Utils/ReactFromNode"
import { getLogoSrc, getStyleObjectFromString, isBrowser } from "Utils/Helpers"
import { parseJson } from "Utils/Validation"

import Arrow from "Assets/Arrow.svg"
import Star from "Assets/Star.svg"
import Social from "Assets/Social.svg"

import Video from "Components/Video"
import ShareWrapper from "Components/ShareWrapper"
import { withTranslation } from "react-i18next"
import ProgressiveImage from "Components/ProgressiveImg"
import { getLanguage } from "Utils/Language"
import ShareButton from "Components/ShareButton"

const SHARE_ID = "#s"
const MIN_SLIDES = 2
const MAX_RATING_VALUE = 5

const ClientStoriesCarouselBlock = ({ node, t, pageContext = {} }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(null)
  const [copySuccess, setCopySuccess] = useState(false)
  const [listMap, setListMap] = useState([])
  const [carouselWrapperStyle, setCarouselWrapperStyle] = useState({})
  const sharePopupRef = useRef(null)
  const inputRef = useRef(null)

  useEffect(() => {
    const handlePopupClose = event => {
      if (activeSlideIndex === null) return

      if (sharePopupRef.current.contains(event.target)) return

      setActiveSlideIndex(null)
    }

    document.addEventListener("mousedown", handlePopupClose)
    return () => document.removeEventListener("mousedown", handlePopupClose)
  }, [activeSlideIndex])

  useEffect(() => {
    if (listMap.length === 0) {
      const newList = node.children
        .map(a => [Math.random(), a])
        .sort((a, b) => a[0] - b[0])
        .map(a => a[1])
      setListMap(newList)
      setCarouselWrapperStyle(getStyleObjectFromString(node?.attribs?.style))
    }
  }, [listMap, node])

  // const getDerivedStateFromProps = (props, state) => {
  //   if (state.listMap.length) return state

  //   // Shuffles existing children array on page refresh.
  //   const newList = props.node.children
  //     .map(a => [Math.random(), a])
  //     .sort((a, b) => a[0] - b[0])
  //     .map(a => a[1])

  //   return {
  //     listMap: newList,
  //     carouselWrapperStyle: getStyleObjectFromString(
  //       props.node?.attribs?.style
  //     ),
  //   }
  // }

  const getGrandchildValueByClassName = (child, elemClassName = "") => {
    if (!elemClassName) return null
    const matchingChild = child.children.find(
      ({ attribs: { class: cssClass } }) =>
        cssClass.split(" ").includes(elemClassName),
    )

    if (!matchingChild) return null
    return createReactFromNode(matchingChild.children)
  }

  const getAuthorImage = child => {
    const matchingChild = child.children.find(
      ({ attribs: { class: cssClass } }) =>
        cssClass.split(" ").includes("AuthorImage"),
    )

    if (!matchingChild) return null
    return createReactFromNode(matchingChild)
  }

  const getMedia = child => {
    const matchingChild = child.children[0]
    if (!matchingChild) return null
    return createReactFromNode(matchingChild)
  }

  const getElements = () => {
    const elements = listMap.reduce(
      (acc, child, index) => {
        const {
          children,
          attribs: { url: hash = "" },
        } = child
        const [mediaWrap, textWrap] = children

        if (!children.length) return acc

        const media = getMedia(mediaWrap)
        const { code } = getLanguage()
        const {
          props: { largeSrc },
        } = media || { props: { largeSrc: getLogoSrc(code) } }

        const image = getAuthorImage(textWrap)
        const microcopy = getGrandchildValueByClassName(textWrap, "Microcopy")
        const [title] = getGrandchildValueByClassName(textWrap, "Title")
        const author = getGrandchildValueByClassName(textWrap, "Author")
        const [content] = getGrandchildValueByClassName(textWrap, "Content")
        const location = getGrandchildValueByClassName(textWrap, "Location")

        const rating = getGrandchildValueByClassName(textWrap, "Rating")
        const parsed = Number(rating)

        if (
          !Number.isNaN(parsed) &&
          parsed >= 1 &&
          parsed <= MAX_RATING_VALUE
        ) {
          acc.sliderListMap.push(
            <ShareWrapper
              isActive={activeSlideIndex === index}
              itemId={index}
              shareId={SHARE_ID}
              key={index}
              metaTitle={title}
              metaImageSrc={largeSrc}
              metaDescription={content}
            >
              <div
                className="MainWrapper"
                key={index}
                id={`${SHARE_ID.replace("#", "")}${index}`}
              >
                <div className="MediaWrapper">{renderMediaContent(media)}</div>
                <div className="TextWrapper">
                  <div className="SlideTitleBlock">
                    {!!image && (
                      <div className="AuthorImage">
                        <ProgressiveImage
                          src={image.props.src}
                          largeSrc={image.props.largeSrc}
                          alt="author"
                        />
                      </div>
                    )}
                    <div className="SlideTitleGroup">
                      {microcopy && <p className="Microcopy">{microcopy}</p>}
                      {title && <h4 className="Title">{title}</h4>}
                    </div>
                  </div>
                  <p className="Rating">{starRating(parsed)}</p>
                  <p className="Content">{content}</p>
                  <div className="Row">
                    <div className="TextWrapperFooter">
                      <p className="Author">{author}</p>
                      {location && <p className="Location">{location}</p>}
                    </div>
                    <Social
                      className="SocialIcon"
                      onClick={() => handlePopupOpen(index)}
                    />
                  </div>
                  <div className="SharePopup">
                    {renderPopup(index, largeSrc, title, content, hash)}
                  </div>
                </div>
              </div>
            </ShareWrapper>,
          )
        }

        return acc
      },
      {
        sliderListMap: [],
      },
    )

    return elements
  }

  const handlePopupOpen = index => {
    setActiveSlideIndex(index)
  }

  const copyCodeToClipboard = () => {
    const el = inputRef.current
    el.select()
    document.execCommand("copy")
    setCopySuccess(true)
  }

  const starRating = rating => {
    const stars = Number(rating)
    const fullStars = Math.floor(stars)
    const fullStarElements = [...new Array(fullStars)].map((_, index) => (
      <Star className="FullStar" key={`star-${index}`} />
    ))

    return stars === fullStars
      ? fullStarElements
      : [
          ...fullStarElements,
          <svg key={`star-${fullStars}`}>
            <defs>
              <linearGradient id="Gradient">
                <stop offset="0%" stopColor="#007377" />
                <stop offset="50%" stopColor="#007377" />
                <stop offset="50%" stopColor="rgba(0, 0, 0, .15)" />
              </linearGradient>
            </defs>
            <Star className="HalfStar" key={`star-${fullStars}`} />
          </svg>,
        ]
  }

  const renderPopup = (index, imageSrc, title, description, hash) => {
    if (!isBrowser) {
      return null
    }

    const { meta } = pageContext ?? {}
    const { hash: pageHash = "" } = meta ?? {}
    const {
      location: { origin, pathname },
    } = window
    const shareUrl = `${origin}${
      pageHash ? pathname.replace(`${pageHash}/`, "") : pathname
    }${hash ? `${hash}/` : ""}${SHARE_ID}${index}`

    if (activeSlideIndex === index) {
      return (
        <div className="ModalPopup" ref={sharePopupRef}>
          <div className="ModalWrapper">
            {renderShareButtons(imageSrc, index, title, description, hash)}
            <p className="Subtitle">
              {t("clientStories.shareLink")}
              {copySuccess ? (
                <span className="SuccessMessage">
                  {t("clientStories.success")}
                </span>
              ) : null}
            </p>
            <div className="ModalLinkRow">
              <input
                ref={inputRef}
                className="UrlLink"
                type="text"
                value={shareUrl}
                readOnly
              />
              <button onClick={copyCodeToClipboard} className="CopyLinkButton">
                {t("clientStories.copyUrl")}
              </button>
            </div>
          </div>
        </div>
      )
    }

    return null
  }

  const renderShareButtons = (src, id, caption, description, hash) => {
    if (!isBrowser) {
      return null
    }

    const {
      t,
      pageContext: { meta = {} },
    } = this.props
    const { hash: pageHash = "" } = meta

    const {
      location: { origin, pathname },
    } = window
    const url = `${origin}${
      pageHash ? pathname.replace(`${pageHash}/`, "") : pathname
    }${hash ? `${hash}/` : ""}${SHARE_ID}${id}`
    const documentTitle = isBrowser ? document.title : ""
    const shareCaption = caption && caption.trim() ? caption : documentTitle

    return (
      <div className="ShareButtonsWrapper">
        <p className="ShareTitle">{t("clientStories.shareWithFriend")}</p>
        <div className="ShareButtons">
          <ShareButton type="facebook" url={url} />
          <ShareButton type="twitter" url={url} twitterTitle={shareCaption} />
          <ShareButton
            type="pinterest"
            url={url}
            media={src}
            description={description}
          />
          <ShareButton
            type="email"
            url={url}
            media={src}
            subject={shareCaption}
            body={description}
          />
        </div>
      </div>
    )
  }

  const renderMediaContent = ({
    props: { className, src, largeSrc, data },
  }) => {
    if (className.split(" ").includes("Image")) {
      return (
        <ProgressiveImage
          src={src}
          largeSrc={largeSrc}
          className="Image"
          alt="media"
        />
      )
    }

    return <Video {...parseJson(data, {})} />
  }

  const renderSlider = sliderMap => {
    return (
      <Slider>
        {sliderMap.map((listItems, index) => (
          <Slide key={index}>
            <div className="ClientStoriesCarouselBlock">{listItems}</div>
          </Slide>
        ))}
      </Slider>
    )
  }

  const renderContent = () => {
    const { sliderListMap, className } = getElements()

    if (sliderListMap.length >= MIN_SLIDES) {
      const arrayOfWidgetSlides = sliderListMap.reduce((acc, item, index) => {
        const listIndex = parseInt(index, 10)

        if (!acc[listIndex]) acc[listIndex] = []

        acc[listIndex].push(item)
        return acc
      }, [])

      const numberOfSlides = 3
      const sliderMap = arrayOfWidgetSlides.slice(0, numberOfSlides)

      const sliderMapLength = sliderMap.length

      return (
        <>
          <CarouselProvider totalSlides={sliderMapLength} infinite>
            <div className="CarouselRow">
              <ButtonBack>
                <Arrow className="CarouselArrow" />
              </ButtonBack>
              {renderSlider(sliderMap, sliderMapLength, className)}
              <ButtonNext>
                <Arrow className="CarouselArrow" />
              </ButtonNext>
            </div>
            <DotGroup />
          </CarouselProvider>
        </>
      )
    }

    return null
  }

  return (
    <div className="ClientStoriesCarouselWrapper" style={carouselWrapperStyle}>
      {renderContent()}
    </div>
  )
}

export default withTranslation()(ClientStoriesCarouselBlock)
