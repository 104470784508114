import { useStaticQuery, graphql } from "gatsby"

export const useInstagramFeed = () => {
  const data = useStaticQuery(graphql`
    query HomePageQuery {
      site {
        siteMetadata {
          description
        }
      }
    }
  `)

  return { data }
}
