import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import HeightTransition from "Components/HeightTransition";
import "./CategoriesSelector.style.scss"; // Import the CSS file

const CategoriesSelector = ({
  categoriesMap,
  activePageCategory,
  defaultButtonTitle = "",
  t,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const categoriesRef = useRef(null);

  useEffect(() => {
    const updateActiveCategory = () => {
      const change = activePageCategory?.change;
      const category = activePageCategory?.name;
      const id = activePageCategory?.id;

      if (!category || id === null) {
        setIsLoaded(true);
        return;
      }

      const categoryIndex = categoriesMap.findIndex(
        ({ category_name }) =>
          category_name.toLowerCase() === category.toLowerCase()
      );

      if (categoryIndex !== -1) {
        const { category_name: name } = categoriesMap[categoryIndex];
        change({ id: categoriesMap[categoryIndex].id, name });
        setIsLoaded(true);
      }
    }

    updateActiveCategory();
  }, [activePageCategory, categoriesMap])

  const handleScroll = () => {
    const scrollLeft = categoriesRef.current.scrollLeft;
    const scrollWidth = categoriesRef.current.scrollWidth;
    const clientWidth = categoriesRef.current.clientWidth;

    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 1); // Hide right arrow when at the last category
  };

  useEffect(() => {
    const currentRef = categoriesRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
      handleScroll();
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const renderSingleCategory = ({ category_name, id = null }) => {
    const change = activePageCategory?.change
    const activeId = activePageCategory?.id

    const isActive = activeId === id && isLoaded ? "isActive" : ""

    const handleClick = () => {
      if (
        category_name === defaultButtonTitle.trim() ||
        category_name === t("categorySelector.all")
      ) {
        change({ id: null, name: "" }) // Reset for "All" category
      } else {
        change({ id, name: category_name })
      }
    }

    return (
      <button
        key={id}
        className={`NoButtonStyle SingleCategory ${isActive}`}
        onClick={handleClick}
      >
        {category_name}
      </button>
    )
  }

  const renderCategories = () => (
    <div className="Categories" ref={categoriesRef}>
      {renderSingleCategory({
        category_name:
          defaultButtonTitle.trim().length > 0
            ? defaultButtonTitle
            : t("categorySelector.all"),
      })}
      {categoriesMap.map(renderSingleCategory)}
    </div>
  );

  const scrollLeft = () => {
    if (categoriesRef.current) {
      categoriesRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (categoriesRef.current) {
      categoriesRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  const renderActiveCategoryTitle = () => {
    const { activePageCategory: { id, name } = {} } = activePageCategory

    const title = id === null ? "" : name

    return (
      <HeightTransition isOpen={id !== null}>
        <h2 className="ActiveCategoryTitle">{title}</h2>
      </HeightTransition>
    )
  }

  return (
    <div className="CategoriesSelector">
      {showLeftArrow && (
        <button className="leftArrow" aria-label="leftArrow" onClick={scrollLeft}>
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m16 4-8 8 8 8"></path>
          </svg>
        </button>
      )}

      <div className="categoriesContainer">
        {renderCategories()}
      </div>

      {renderActiveCategoryTitle()}

      {showRightArrow && (
        <button className="rightArrow" aria-label="rightArrow" onClick={scrollRight}>
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m8 4 8 8-8 8"></path>
          </svg>
        </button>
      )}
    </div>
  )
}

CategoriesSelector.propTypes = {
  categoriesMap: PropTypes.arrayOf(
    PropTypes.shape({
      category_name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  activePageCategory: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func]),
  ).isRequired,
  defaultButtonTitle: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

export default withTranslation()(CategoriesSelector)
