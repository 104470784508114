import { useState, useEffect, cloneElement } from "react"
import CookieService from "Utils/CookieService"
import { getGeolocation } from "Utils/WpRequests"
import { languages } from "Utils/Language"
import { isBrowser } from "Utils/Helpers"
import axios from "axios"

const Geolocation = ({ children }) => {
  const [, setGeoContinent] = useState(null)
  const [geoCountry, setGeoCountry] = useState(null)
  const [geoSub, setGeoSub] = useState(null)
  const [suggestedPostalCode, setSuggestedPostalCode] = useState("")

  useEffect(() => {
    const setCookies = async ipAddress => {
      const data = await getGeolocation(ipAddress)
      if (!data) return

      const {
        continent: { code: continentCode } = {},
        country: { iso_code3: countryCode } = {},
        postal: { code: postalCode } = {},
        subdivisions: [{ names: { en: subDivision } } = {}] = [],
        city: { names: { en: cityName } } = {},
      } = data

      if (postalCode) setSuggestedPostalCode(postalCode)
      if (continentCode) setGeoContinent(continentCode)
      if (countryCode) setGeoCountry(countryCode)
      if (subDivision) setGeoSub(subDivision)

      const setGeoCookieIfProperty = (cookieName, hasProperty, value) => {
        if (!hasProperty) return

        setGeoCookie(cookieName, () =>
          CookieService.set(cookieName, value, { path: "/" }),
        )
      }

      setGeoCookieIfProperty("bf_geolocation_sub", subDivision, subDivision)
      setGeoCookieIfProperty("bf_geolocation_city", cityName, cityName)
      setGeoCookieIfProperty("bf_geolocation_country", countryCode, countryCode)
      setGeoCookieIfProperty("bf_geolocation_postal", postalCode, postalCode)

      setGeoCookieIfProperty(
        "bf_geolocation_continent",
        continentCode,
        continentCode,
      )
    }

    const fetchGeoLocation = async () => {
      if (isBrowser) {
        try {
          const res = await axios.get("https://api.ipify.org?format=json")

          await setCookies(res?.data?.ip)
        } catch (error) {
          //
        }
      }
    }
    fetchGeoLocation()
  }, [])

  const setGeoCookie = (prop, hasProperty, callback) => {
    if (CookieService.get(prop) === undefined) {
      if (hasProperty) {
        callback()
      } else {
        CookieService.set(prop, "-", { path: "/" })
      }
    }
  }

  const getLocaleSuggestion = () => {
    // const continent =
    //   CookieService.get("bf_geolocation_continent") || geoContinent

    const country = CookieService.get("bf_geolocation_country") || geoCountry
    const sub = CookieService.get("bf_geolocation_sub") || geoSub

    const languageObject = (language, isSuggestionCodeDefault = false) => ({
      language,
      isSuggestionCodeDefault,
    })

    if (country === "CAN") {
      if (sub === "Quebec") return languageObject(languages.qc_fr)
      return languageObject(languages.ca_en)
    }

    if (country === "USA") return languageObject(languages.us_en)
    return languageObject(languages.us_en, true)
  }

  const {
    language: { code: suggestionCode },
    isSuggestionCodeDefault,
  } = getLocaleSuggestion()

  return cloneElement(children, {
    suggestionCode,
    isSuggestionCodeDefault,
    suggestedPostalCode,
  })
}

export default Geolocation
