import React from "react"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonNext,
  ButtonBack,
} from "pure-react-carousel"
import BlogPostCard from "Components/BlogPostCard"
import "./BlogLatestPosts.style.scss"

const BlogLatestPosts = ({
  postMap,
  maxPostsInSlide,
  totalSlides,
  blockTitle,
  backgroundColor,
  visibleSlides,
}) => {
  const renderArrow = isNext => {
    if (totalSlides === 1) return null

    const Tag = isNext ? ButtonNext : ButtonBack
    return (
      <Tag className={`NoButtonStyle ArrowButton ${isNext ? "isNext" : ""}`} />
    )
  }

  const renderSinglePost = post => (
    <Slide
      key={`${post.id}-${maxPostsInSlide}`}
      index={post.id}
      className="SliderSlide"
    >
      <BlogPostCard
        key={post.id}
        post={post}
        showedElems={["image", "category", "title"]}
      />
    </Slide>
  )

  const renderTitle = () =>
    blockTitle && <h2 className="BlogBlockTitle">{blockTitle}</h2>

  return (
    <div className="BlogLatestPosts" style={{ backgroundColor }}>
      <div className="ContentWrapper">
        {renderTitle()}
        <CarouselProvider
          totalSlides={totalSlides}
          visibleSlides={visibleSlides}
          infinite
          className="Carousel"
          lockOnWindowScroll
        >
          {renderArrow()}
          <Slider className="Slider">{postMap.map(renderSinglePost)}</Slider>
          {renderArrow(true)}
        </CarouselProvider>
      </div>
    </div>
  )
}

export default BlogLatestPosts
