import React from "react"
import { createReactFromNode } from "Utils/ReactFromNode"
import GalleryFeaturesListing from "./GalleryFeaturesListing.component"

const GalleryFeaturesListingContainer = props => {
  const { node } = props

  const createData = () => {
    const { children } = node
    if (children.length < 2) return null

    const {
      0: {
        children: tagsMap,
        attribs: { featuredtags },
      },
      1: { children: imagesMap },
    } = children

    const featuredTagIds = featuredtags.split(",")

    const tagsData = tagsMap.reduce(
      (
        acc,
        {
          children: {
            0: { data },
          },
          attribs: { id },
        },
      ) => {
        acc[id] = {
          value: data,
          id,
        }

        return acc
      },
      {},
    )

    const imagesData = imagesMap.map(
      ({ attribs: { id, url = "", tagids }, children }) => {
        const dataObject = {
          id,
          tagIds: tagids ? tagids.split(",") : [],
          ...children.reduce(
            (
              acc,
              { name, attribs: { src, largeSrc, class: className }, children },
            ) => {
              if (name === "img") {
                acc.hash = url
                if (className && className.includes("ImageAfter")) {
                  acc.afterLargeSrc = largeSrc
                  acc.afterSrc = src
                  return acc
                }
                acc.largeSrc = largeSrc
                acc.src = src
                return acc
              }
              const data = createReactFromNode(children)
              if (!data) return acc
              if (className === "HighlightTitle") acc.highlightTitle = data[0]
              if (className === "Title") acc.caption = data[0]
              if (className === "Description") acc.description = data[0]
              return acc
            },
            {},
          ),
        }
        return dataObject
      },
    )
    return {
      imagesData,
      tagsData,
      featuredTagIds,
    }
  }

  return <GalleryFeaturesListing {...props} {...createData()} />
}

export default GalleryFeaturesListingContainer
