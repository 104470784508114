import React from "react"
import { useSharedContext } from "Src/contexts/SharedContext"
import LocaleSelector from "Components/LocaleSelector/LocaleSelector.component"

const LocaleSelectorContainer = props => {
  const { navigateToTranslation } = useSharedContext()
  return (
    <LocaleSelector navigateToTranslation={navigateToTranslation} {...props} />
  )
}

export default LocaleSelectorContainer
