import React from "react"
import { useSharedContext } from "Src/contexts/SharedContext"
import ComparisonBlockTable from "./ComparisonBlockTable.component"
import ComparisonBlockTableMobile from "./ComparisonBlockTableMobile.component"

const ComparisonBlockTableContainer = ({ body = [], borderColor = "" }) => {
  const { isMobile } = useSharedContext()

  const getBody = () => {
    const transposedBody = body[0].cells.map((_, colIndex) =>
      body.map(row => row.cells[colIndex]),
    )

    return transposedBody.reduce(
      (acc, data, i) => {
        // eslint-disable-next-line no-nested-ternary
        const firstLevelId = i
          ? i === transposedBody.length - 1
            ? "lastColumn"
            : "middleColumn"
          : "firstColumn"

        acc[firstLevelId].push(data)

        return acc
      },
      { firstColumn: [], middleColumn: [], lastColumn: [] },
    )
  }

  const { firstColumn, middleColumn, lastColumn } = getBody()
  const columnCount = body[0].cells.length

  if (isMobile) {
    return (
      <ComparisonBlockTableMobile
        firstColumn={firstColumn}
        middleColumn={middleColumn}
        lastColumn={lastColumn}
        columnCount={columnCount}
        borderColor={borderColor}
      />
    )
  }

  return (
    <ComparisonBlockTable
      body={body}
      columnCount={columnCount}
      borderColor={borderColor}
    />
  )
}

export default ComparisonBlockTableContainer
