import React, { useState, useEffect, useRef } from "react"
import YouTube from "react-youtube"
import "./EmbeddedVideo.style.scss"

const EmbeddedVideo = ({
  aspectRatio = { width: 16, height: 9 },
  id = "",
  isCovered = false,
  options = { isMute: false, isLoop: false, isAutoplay: false },
  type,
  children,
}) => {
  const ref = useRef(null)
  const [videoSize, setVideoSize] = useState("")

  useEffect(() => {
    const setVideoRatio = () => {
      const prevVideoSize = videoSize
      const { width, height } = aspectRatio
      const { offsetWidth, offsetHeight } = ref.current.parentNode

      const videoRatioNumber = offsetWidth / width / (offsetHeight / height)
      const newVideoSize = videoRatioNumber < 1 ? "widerHeight" : "widerWidth"

      if (newVideoSize !== prevVideoSize) {
        setVideoSize(newVideoSize)
      }
    }

    setVideoRatio()

    const handleScreenResize = () => setVideoRatio()

    window.addEventListener("resize", handleScreenResize)
    return () => window.removeEventListener("resize", handleScreenResize)
  }, [aspectRatio, videoSize])

  const onVideoReady = e => {
    const { isAutoplay } = options
    if (isAutoplay) e.target.playVideo()
  }

  const renderContent = () => {
    if (type === "iframe") return children

    const { isAutoplay, isLoop, isMute } = options

    const opts = {
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: Number(isAutoplay),
        loop: Number(isLoop),
        mute: Number(isMute),
        playlist: isLoop ? id : "",
      },
    }

    return <YouTube videoId={id} opts={opts} onReady={onVideoReady} />
  }

  return (
    <div
      ref={ref}
      className={`EmbeddedVideo${isCovered ? " isCovered" : ""} ${videoSize}`}
    >
      {renderContent()}
    </div>
  )
}

export default EmbeddedVideo
