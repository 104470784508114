import React, { useState, useEffect } from "react"
import { CarouselProvider, Slider, Slide, DotGroup } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { createReactFromNode } from "Utils/ReactFromNode"
import Star from "Assets/Star.svg"

const RATING_INDEX = 1
const DESCRIPTION_INDEX = 2
const AUTHOR_INDEX = 3
const AUTHOR_DESC_INDEX = 4
const MAX_RATING_VALUE = 5

const ClientStorySlider = ({ node }) => {
  const [listMap, setListMap] = useState([])

  useEffect(() => {
    // Shuffles node children on page refresh.
    if (!listMap.length) {
      const newList = node.children
        .map(a => [Math.random(), a])
        .sort((a, b) => a[0] - b[0])
        .map(a => a[1])
      setListMap(newList)
    }
  }, [listMap, node.children])

  const getGrandChildValue = (child, firstChildrenIndex = 0) => {
    const {
      children: {
        [firstChildrenIndex]: { children },
      },
    } = child

    return createReactFromNode(children)
  }

  const starRating = rating => {
    const stars = Number(rating)

    if (!Number.isNaN(stars) && stars >= 1 && stars <= MAX_RATING_VALUE) {
      const fullStars = Math.floor(stars)

      const fullStarElements = [...new Array(fullStars)].map((_, index) => (
        <Star className="FullStar" key={`star-${index}`} />
      ))

      return stars === fullStars
        ? fullStarElements
        : [
            ...fullStarElements,
            <Star
              key={`star-${fullStars}`}
              viewBox="-9 0 18 18"
              style={{ left: "-9px" }}
            />,
          ]
    }

    return null
  }

  const renderSlider = (sliderMap, sliderMapLength) => {
    if (sliderMapLength === 1) {
      const { 0: listItem } = sliderMap

      return (
        <div className="ContentWithoutSlider">
          <ul className="ClientStory-Slider">{listItem}</ul>
        </div>
      )
    }

    return (
      <Slider>
        {sliderMap.map((listItems, index) => (
          <Slide key={index}>
            <ul className="ClientStory-Slider">{listItems}</ul>
          </Slide>
        ))}
      </Slider>
    )
  }

  const renderContent = () => {
    const { blockList, microcopy, linkButton } = getElements()

    const sliderMap = blockList.reduce((acc, item, index) => {
      const listIndex = parseInt(index, 10)

      if (!acc[listIndex]) acc[listIndex] = []

      acc[listIndex].push(item)

      return acc
    }, [])

    const sliderMapLength = sliderMap.length

    return (
      <CarouselProvider totalSlides={sliderMapLength} infinite>
        {microcopy && microcopy}
        {renderSlider(sliderMap, sliderMapLength)}
        {linkButton && linkButton}
        {sliderMapLength > 1 && <DotGroup />}
      </CarouselProvider>
    )
  }

  const getElements = () => {
    const elements = listMap.reduce(
      (acc, child, index) => {
        const {
          children,
          name,
          attribs: { class: className },
        } = child

        if (!children.length) return acc
        if (name === "li") {
          const title = getGrandChildValue(child)
          const rating = getGrandChildValue(child, RATING_INDEX)
          const description = getGrandChildValue(child, DESCRIPTION_INDEX)
          const author = getGrandChildValue(child, AUTHOR_INDEX)
          const readDescription = getGrandChildValue(child, AUTHOR_DESC_INDEX)

          const parsed = Number(rating)

          if (title || parsed || description || author || readDescription) {
            acc.blockList.push(
              <li key={index} className={`StoryItem ${className}`}>
                {title && <div className="Title">{title}</div>}
                {rating && starRating(parsed)}
                {description && (
                  <p className="ItemDescription">{description}</p>
                )}
                {author && <p className="ItemAuthor">{author}</p>}
                {readDescription && (
                  <p className="ItemAuthorDescription">{readDescription}</p>
                )}
              </li>,
            )
          }
        }

        if (className === "Microcopy")
          acc.microcopy = createReactFromNode(child)
        if (className === "LinkButton")
          acc.linkButton = createReactFromNode(child)

        return acc
      },
      {
        microcopy: "",
        linkButton: "",
        blockList: [],
      },
    )

    return elements
  }

  return <div className="ReviewWrapper">{renderContent()}</div>
}

export default ClientStorySlider
