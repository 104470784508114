import { isBrowser } from "Utils/Helpers"
import moment from "moment"
import getUuid from "uuid-by-string"

/**
 * Unique ID used for Quantic Mind event deduplication
 */
const gtmGenerateMessageId = fields => {
  const data = `${JSON.stringify(fields)}${moment().unix()}`

  return getUuid(data)
}

/**
 * Unique ID used for Quantic Mind event deduplication
 * @param {string} event
 * @param {object} fields
 */
const gtmFormEventPush = (event, fields) => {
  if (!isBrowser || !window.dataLayer) return

  const eventData = {
    event,
    ...fields,
    messageId: gtmGenerateMessageId(fields),
  }

  window.dataLayer.push(eventData)
}

export { gtmGenerateMessageId, gtmFormEventPush }
