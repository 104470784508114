import React from "react"
import { htmlToReact } from "Utils/HtmlParser"
import ShareButtonGroup from "Components/ShareButtonGroup"
import { BlogPostCardCategories } from "Components/BlogPostCard"
import "./BlogPostHeader.style.scss"
import ProgressiveImage from "Components/ProgressiveImg"
import { getPreviewImageSrc } from "Utils/Helpers"

const BlogPostHeader = ({
  categories,
  featuredMediaSrc,
  title,
  description,
}) => {
  const renderCategories = () => (
    <BlogPostCardCategories categories={categories} />
  )

  const renderFeatureImage = () => {
    if (!featuredMediaSrc) return null

    return (
      <div className="ImageWrapper">
        <ProgressiveImage
          src={getPreviewImageSrc(featuredMediaSrc)}
          largeSrc={featuredMediaSrc}
          className="Image"
          alt="Post"
        />
      </div>
    )
  }

  const renderTitle = () => {
    if (!title) return null

    return <h1 className="Title">{htmlToReact(title.rendered)}</h1>
  }

  const renderDescription = () => {
    if (!description) return null

    return <div className="Description">{htmlToReact(description)}</div>
  }

  const renderSocialSharing = () => {
    return (
      <div className="ShareWrapper">
        <ShareButtonGroup
          title={title.rendered}
          description={description}
          featuredMediaSrc={featuredMediaSrc}
        />
      </div>
    )
  }

  return (
    <div className="BlogPostHeader">
      {renderCategories()}
      {renderTitle()}
      {renderDescription()}
      {renderSocialSharing()}
      {renderFeatureImage()}
    </div>
  )
}

export default BlogPostHeader
