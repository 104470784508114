import { useStaticQuery, graphql } from "gatsby"

export const useHeader = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      headerAnnouncement: allWidgets(
        filter: { parent_sidebar: { eq: "bathfitter-header-announcement" } }
      ) {
        nodes {
          rendered
          parent_sidebar
          id
          language
        }
      }
      bookConsultationForm: allWidgets(
        filter: {
          parent_sidebar: { eq: "bathfitter-header-book-consultation-form" }
        }
      ) {
        nodes {
          rendered
          parent_sidebar
          id
          language
          nf_assets {
            fields {
              default
              id
              key
              label
              placeholder
              type
              value
              options {
                label
                calc
                value
              }
            }
            title
            formID
            success_message
          }
        }
      }
      logo: allWidgets(filter: { parent_sidebar: { eq: "bathfitter-logo" } }) {
        nodes {
          rendered
          parent_sidebar
          id
          language
        }
      }
      bookConsultationB2BForm: allWidgets(
        filter: {
          parent_sidebar: { eq: "bathfitter-header-book-consultation-b2b-form" }
        }
      ) {
        nodes {
          rendered
          parent_sidebar
          id
          language
        }
      }
      logo: allWidgets(filter: { parent_sidebar: { eq: "bathfitter-logo" } }) {
        nodes {
          rendered
          parent_sidebar
          id
          language
        }
      }
      commercialSalesPhone: allWidgets(
        filter: { parent_sidebar: { eq: "bathfitter-commercial-sales-phone" } }
      ) {
        nodes {
          rendered
          parent_sidebar
          id
          language
        }
      }
      commercialSalesAnnouncement: allWidgets(
        filter: {
          parent_sidebar: {
            eq: "bathfitter-commercial-sales-header-announcement"
          }
        }
      ) {
        nodes {
          rendered
          parent_sidebar
          id
          language
        }
      }
      phoneNumber: allWidgets(
        filter: { parent_sidebar: { eq: "bathfitter-header-phone-number" } }
      ) {
        nodes {
          rendered
          parent_sidebar
          id
          language
        }
      }
      allMenus: allMenuItems(filter: { slug: { regex: "/^header-menu/" } }) {
        nodes {
          slug
          items {
            title
            object_slug
            object_id
            type
            url
            children {
              title
              url
              type
              object_slug
              object_id
              featured_image
              children {
                title
                url
                type
                object_slug
                object_id
                featured_image
              }
            }
          }
        }
      }
      commercialMenus: allMenuItems(
        filter: { slug: { regex: "/^commercial-header-menu/" } }
      ) {
        nodes {
          slug
          items {
            title
            object_slug
            object_id
            type
            url
            children {
              title
              url
              type
              object_slug
              object_id
              featured_image
              children {
                title
                url
                type
                object_slug
                object_id
                featured_image
              }
            }
          }
        }
      }
    }
  `)

  return {
    data,
  }
}
