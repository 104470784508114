import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import { navigate } from "gatsby"

import ModalPopup from "Components/ModalPopup"
import { isBrowser } from "Utils/Helpers"
import CookieService from "Utils/CookieService"
import { useSharedContext } from "Src/contexts/SharedContext"
import {
  defaultLanguageCode,
  formatToWpLanguage,
  getLanguage,
} from "Utils/Language"

import Cancel from "Assets/Cancel.svg"

import "./BookConsultation.style.scss"

const BookB2BConsultation = ({
  widgets = {},
  languageCode = defaultLanguageCode,
  t,
}) => {
  const context = useSharedContext()
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (isBrowser) {
      window.bookConsultationRedirect = bookConsultationRedirect
    }
  }, [])

  useEffect(() => {
    const handleContextModalOpen = () => {
      context.setIsConsultaionModalOpen(false)
      setModalOpen(true)
    }

    if (!modalOpen && context.isBookConsultaionModalOpen) {
      handleContextModalOpen()
    }
  }, [modalOpen, context])

  const bookConsultationRedirect = response => {
    const {
      data: { firstname, lastname, zipcode },
      response: id,
      type,
    } = response
    if (type !== "error" && response.response.type !== "error") {
      const leadData = {
        id,
        firstName: firstname,
        lastName: lastname,
        zipCode: zipcode,
      }
      const date = new Date()
      const expireTime = 60 * 45 * 1000
      date.setTime(date.getTime() + expireTime)
      CookieService.set("bf_lead_data", leadData, { path: "/", expires: date })

      const { url } = getLanguage()
      if (url === "/qc-fr") {
        navigate(`${url}/merci`)
      } else {
        navigate(`${url}/thankyou`)
      }
    }
  }

  const triggerFormModal = () => {
    setModalOpen(!modalOpen)
  }

  const afterModalOpen = () => {
    document.body.style.overflow = "hidden"
    if (!widgets || !widgets.nodes) return

    widgets.nodes.forEach(widget => {
      if (
        widget.language !== null &&
        (widget.language !== formatToWpLanguage(languageCode) ||
          widget.language === null)
      )
        return null

      // const {
      //   cf_assets: { scripts, cf_data },
      // } = widget

      // loadAssets(`${scripts} ${cf_data}`, this.cfAssetsContainerId)
    })
  }

  const afterModalClose = () => {
    document.body.style.overflow = "initial"
  }

  const renderModal = () => {
    if (!widgets || !widgets.nodes) return null

    return widgets.nodes.map((widget, i) => {
      if (
        widget.language !== null &&
        (widget.language !== formatToWpLanguage(languageCode) ||
          widget.language === null)
      )
        return null

      const { name, custom_class_name, rendered } = widget

      //      const { styles } = cf_assets || {}

      return (
        <React.Fragment key={`${name}-${i}`}>
          <button
            className="Button Header-Button BookConsultation"
            onClick={triggerFormModal}
          >
            {t("header.bookConsultation")}
          </button>
          <ModalPopup
            isVisible={modalOpen}
            handleClose={triggerFormModal}
            modalOptions={{
              shouldCloseOnOverlayClick: true,
              style: {
                content: {
                  top: "50%",
                  left: "50%",
                  right: "initial",
                  bottom: "initial",
                  overflow: "visible",
                  borderRadius: "2px",
                  padding: "15px",
                },
              },
            }}
            onAfterOpen={afterModalOpen}
            onAfterClose={afterModalClose}
          >
            <Cancel onClick={triggerFormModal} className="CancelButton" />
            <div className="BookConsultationFormContainer">
              {/* eslint-disable-next-line react/no-danger */}
              {/* styles && <div dangerouslySetInnerHTML={{ __html: styles }} /> */}
              <div
                className={custom_class_name}
                dangerouslySetInnerHTML={{ __html: rendered }}
              />
              {/*<div id={this.cfAssetsContainerId} /> */}
            </div>
          </ModalPopup>
        </React.Fragment>
      )
    })
  }

  return <>{renderModal()}</>
}

export default withTranslation()(BookB2BConsultation)
