const validateZipCode = (zip, language, suggestionCode) => {
  let canadianPostalCodePattern =
    /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$|^([A-Za-z]\d[A-Za-z])[- ](\d[A-Za-z]\d)$/
  let usZipCodePattern = /^\d{5}$/
  if (suggestionCode && suggestionCode !== language) {
    if (
      (language === "ca_en" || language === "qc_en" || language === "qc_fr") &&
      (suggestionCode === "us_en" || suggestionCode === "us_es")
    ) {
      return canadianPostalCodePattern.test(zip) || usZipCodePattern.test(zip)
    }
    if (
      (language === "us_en" || language === "us_es") &&
      (suggestionCode === "ca_en" ||
        suggestionCode === "qc_en" ||
        suggestionCode === "qc_fr")
    ) {
      return canadianPostalCodePattern.test(zip) || usZipCodePattern.test(zip)
    }
    if (language === "us_en" && suggestionCode === "us_es") {
      return usZipCodePattern.test(zip)
    }
    if (language === "us_es" && suggestionCode === "us_en") {
      return usZipCodePattern.test(zip)
    }

    if (
      (language === "ca_en" || language === "qc_en" || language === "qc_fr") &&
      (suggestionCode === "ca_en" ||
        suggestionCode === "qc_en" ||
        suggestionCode === "qc_fr")
    ) {
      return canadianPostalCodePattern.test(zip)
    }
  } else {
    // Otherwise, validate based on the language
    if (language === "ca_en" || language === "qc_en" || language === "qc_fr") {
      // Canadian postal code pattern
      // let canadianPostalCodePattern = /^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/;
      return canadianPostalCodePattern.test(zip)
    } else {
      // US zip code pattern
      // let usZipCodePattern = /^\d{5}$/;
      return usZipCodePattern.test(zip)
    }
  }
}

const parseJson = (json, defaultValue) => {
  try {
    return JSON.parse(json)
  } catch {
    return defaultValue
  }
}

export { validateZipCode, parseJson }
