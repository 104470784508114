import { getLanguage } from "Utils/Language"

const getMenuLink = ({ type, object_slug, url = "" }) => {
  const { url: languageUrl } = getLanguage()
  if (type === "custom") {
    return {
      url,
      isExternal: true,
    }
  }

  return {
    url: `${languageUrl}/${object_slug}`,
    isExternal: false,
  }
}

// eslint-disable-next-line import/prefer-default-export
export { getMenuLink }
