import React from "react"

import { useSharedContext } from "Src/contexts/SharedContext"
import { Link as LinkComponent } from "./Link.component"

const LinkContainer = props => {
  const { pathName } = useSharedContext()

  return <LinkComponent pathnameurl={pathName} {...props} />
}

export default LinkContainer
