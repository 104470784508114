import React from "react"
import { useSharedContext } from "Src/contexts/SharedContext"
import BlogPostCardCategories from "./BlogPostCardCategories.component"

const BlogPostCardCategoriesContainer = props => {
  const { activePageCategory } = useSharedContext()

  return (
    <BlogPostCardCategories
      {...props}
      activePageCategory={activePageCategory}
    />
  )
}

export default BlogPostCardCategoriesContainer
