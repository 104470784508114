import React from "react"
import LocaleSwitcher from "./LocaleSwitcher.component"
import { useLocation } from "@reach/router"

const LocaleSwitcherContainer = props => {
  const { pathName } = useLocation()

  return <LocaleSwitcher pathName={pathName} {...props} />
}

export default LocaleSwitcherContainer
