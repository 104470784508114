import React from "react"
import BlogPostCard from "./BlogPostCard.component"

const BlogPostCardContainer = ({
  post,
  showedElems,
  customRenderAfterImage = () => {},
}) => {
  const getDynamicComponentProps = () => {
    return showedElems.reduce((acc, elem) => {
      const isValPassedAsObj = typeof elem === "object"
      const id = isValPassedAsObj ? elem.id : elem

      if (id === "title") {
        acc.title = post.title || ""
      }

      if (id === "image") {
        const { featured_media } = post
        if (featured_media) {
          acc.imageSrc = featured_media.source_url || ""
          if (isValPassedAsObj) {
            acc.imageStyle = elem.style || {}
          }
        }
      }

      if (id === "category" || id === "categories") {
        acc.categories = post.categories || []
      }

      return acc
    }, {})
  }

  if (!post) return null

  const { wordpress_id: id, slug } = post

  return (
    <BlogPostCard
      id={id}
      slug={slug}
      customRenderAfterImage={customRenderAfterImage}
      {...getDynamicComponentProps()}
    />
  )
}

export default BlogPostCardContainer
