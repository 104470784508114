import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { htmlToReact } from "Utils/HtmlParser"

import "./InstagramFeed.style.scss"

const InstagramFeed = ({
  instaImageMap = [],
  microcopy,
  blockTitle,
  bannerTitle,
  hashtag = "",
}) => {
  const renderSingleInstaImage = ({ id, preview }) => {
    if (!preview) return null

    const href = `https://instagram.com/p/${id}`

    return (
      <div className="InstaSingleImageWrapper" key={id}>
        <a
          className="InstaSingleImage"
          href={href}
          target="_blank"
          rel="noreferrer"
          aria-label="_blank"
        >
          <GatsbyImage
            image={{ src: preview, width: "100%", height: "100%" }}
          />
        </a>
      </div>
    )
  }

  const renderInstaImages = () => {
    const checkHashtag = tag => {
      if (!hashtag) return true

      return tag === hashtag
    }

    return (
      <div className="InstaImages">
        {instaImageMap
          .filter(({ hashtags }) => hashtags.find(checkHashtag))
          .map(renderSingleInstaImage)
          .slice(0, 6)}
      </div>
    )
  }

  const renderContent = (type, className) => {
    if (!type) return null
    return <p className={className}>{htmlToReact(type)}</p>
  }

  return (
    <div className="InstagramFeed">
      <div className="TextContent">
        {renderContent(microcopy, "Microcopy")}
        {renderContent(blockTitle, "BlockTitle")}
        {renderContent(bannerTitle, "HashtagButton")}
      </div>

      {renderInstaImages()}
    </div>
  )
}

export default React.memo(InstagramFeed)
