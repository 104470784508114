import React from "react"
import { useSharedContext } from "Src/contexts/SharedContext"
import { getLanguage } from "Utils/Language"

import BlogSecondaryPosts from "./BlogSecondaryPosts.component"

export const POST_COUNT = 2
export const LATEST_POST_COUNT = 5

const BlogSecondaryPostsContainer = ({
  posts = [],
  postCount = POST_COUNT,
  latestPostCount = LATEST_POST_COUNT,
  blockTitle = "",
  backgroundColor = "",
}) => {
  const context = useSharedContext()

  const getPostMap = () => {
    const prevPosts = posts.filter(
      post => post.lang === getLanguage().url.replace("/", ""),
    )
    const activeCategory = context.activePageCategory.name

    if (prevPosts.length <= latestPostCount) {
      return []
    }

    let recentPosts = [...prevPosts]

    if (activeCategory) {
      recentPosts = recentPosts.filter(
        post =>
          post.categories &&
          post.categories.find(
            ({ category_name }) =>
              category_name.toLowerCase() === activeCategory.toLowerCase(),
          ),
      )
    }

    const seenTitles = new Set()
    recentPosts = recentPosts.filter(post => {
      if (!seenTitles.has(post.title)) {
        seenTitles.add(post.title)
        return true
      }
      return false
    })

    recentPosts = recentPosts.slice(0, postCount)

    return recentPosts
  }

  const recentPosts = getPostMap()
  const totalCount = recentPosts.length

  if (!totalCount || !posts.length) return null

  return (
    <BlogSecondaryPosts
      recent_posts={recentPosts}
      totalCount={totalCount}
      blockTitle={blockTitle}
      backgroundColor={backgroundColor}
    />
  )
}

export default BlogSecondaryPostsContainer
