import React from "react"
import { Location } from "@reach/router"
import Geolocation from "Components/Geolocation"
import Events from "Components/Events"
import { LocationFinderProvider } from "./LocationFinderContext"
import { SharedContextProvider } from "./SharedContext"
import { NinjaContextProvider } from "./NinjaFormContext"

const ProviderWrapper = ({ element }) => (
  <Location>
    {({ location }) => (
      <Geolocation>
        <SharedContextProvider location={location}>
          <LocationFinderProvider location={location}>
            <Events location={location} />
            <NinjaContextProvider>{element}</NinjaContextProvider>
          </LocationFinderProvider>
        </SharedContextProvider>
      </Geolocation>
    )}
  </Location>
)

export default ProviderWrapper
