import React from "react"
import ReactModal from "react-modal"

import "./ModalPopup.style.scss"

ReactModal.setAppElement("#___gatsby")

const blockName = "ModalPopup"

const ModalPopup = ({
  modalOptions = {},
  showCloseButton = false,
  className = "",
  handleClose = () => {},
  onAfterOpen = () => {
    document.body.style.overflow = "hidden"
  },
  onAfterClose = () => {
    document.body.style.overflow = "initial"
  },

  children,
  isVisible,
}) => (
  <ReactModal
    overlayClassName={`${blockName} ${className}`}
    isOpen={isVisible}
    onRequestClose={() => handleClose()}
    onAfterOpen={() => onAfterOpen()}
    onAfterClose={() => onAfterClose()}
    style={{
      content: {
        top: "50%",
        left: "50%",
        right: "initial",
        bottom: "initial",
        overflow: "hidden",
        borderRadius: "2px",
        padding: "15px",
      },
    }}
    {...modalOptions}
  >
    <div className="ContentWrapper">{children}</div>
    {showCloseButton && (
      <button onClick={() => handleClose()} className="CloseButton">
        Close
      </button>
    )}
  </ReactModal>
)

export default ModalPopup
