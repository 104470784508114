import { useEffect } from "react"
import { isBrowser } from "Utils/Helpers"

const attributeForLoadedEvents = "loaded"
const eventsBlocksClass = ".TribeEvents"
const eventsPrimaryButtonClass = ".EventsPrimaryButton"
const theEventsPageHeading = "TheEventsCalendarHeading"
const selectorForHeading = "main > h1"

const Events = () => {
  useEffect(() => {
    const setAttrToHtml = (events, loadMore, eventIdToLoad) => {
      if (eventIdToLoad < 0) return true

      if (events[eventIdToLoad]) {
        events[eventIdToLoad].setAttribute("style", "display : block")
      } else {
        loadMore.setAttribute("style", "display : none")
      }

      if (!events[eventIdToLoad + 1]) {
        loadMore.setAttribute("style", "display : none")
      }

      return setAttrToHtml(events, loadMore, eventIdToLoad - 1)
    }

    const loadMoreEvents = eventsPrimaryButtonElement => {
      const loadMore = eventsPrimaryButtonElement.target
      const events = document.querySelectorAll(eventsBlocksClass)
      const moreEventsNumber = 4
      const amountOfEventToLoad =
        parseInt(loadMore.getAttribute(attributeForLoadedEvents), 10) +
        moreEventsNumber
      loadMore.setAttribute(attributeForLoadedEvents, amountOfEventToLoad)

      setAttrToHtml(events, loadMore, amountOfEventToLoad)
    }

    const addOrRemoveClassToHeading = (action = false) => {
      const selector = document.querySelector(selectorForHeading)

      if (selector) {
        return action
          ? selector.classList.add(theEventsPageHeading)
          : selector.classList.remove(theEventsPageHeading)
      }

      return true
    }

    const handleEvents = () => {
      const events = document.querySelectorAll(eventsBlocksClass)
      const loadEvents = document.querySelector(eventsPrimaryButtonClass)
      const eventsLengthToCompare = 5
      const loadedAttr = 3

      addOrRemoveClassToHeading(true)

      if (loadEvents) {
        if (!events || events.length < eventsLengthToCompare) {
          loadEvents.setAttribute("style", "display : none")
        } else {
          loadEvents.setAttribute(attributeForLoadedEvents, loadedAttr)
          loadEvents.addEventListener("click", loadMoreEvents)
        }
      }
    }

    const shouldLogicRender = () => {
      if (!isBrowser) return false

      return document.querySelector(eventsPrimaryButtonClass)
        ? handleEvents()
        : addOrRemoveClassToHeading()
    }

    shouldLogicRender()
  }, [])

  return null
}

export default Events
