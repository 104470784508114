import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { useSharedContext } from "Src/contexts/SharedContext"
import ModalPopup from "Components/ModalPopup"
import BeforeAndAfterPicture from "Components/BeforeAndAfterPicture"
import BlockTransition from "Components/BlockTransition"
import ShareWrapper from "Components/ShareWrapper"

import "./TagsBlock.style.scss"
import ProgressiveImage from "Components/ProgressiveImg"
import { useLocation } from "@reach/router"

const SHARE_ID = "#i"

const TagsBlock = ({
  elemsTransition = [],
  pageContext = {},

  imagesData,
  tagsData,
  t,
}) => {
  const [imagesDatas, setImagesData] = useState(imagesData)
  const [activeTagIndex, setActiveTagIndex] = useState(null)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupImageData, setPopupImageData] = useState({})
  const { isMobile } = useSharedContext()

  const { pathname } = useLocation()

  const getRowCount = () => {
    const mobileRowCount = 2
    const desktopRowCount = 4
    return isMobile ? mobileRowCount : desktopRowCount
  }

  const handlePopupClose = () => {
    setIsPopupOpen(false)
  }

  const handleShareToNotActiveChange = () => {
    setIsPopupOpen(false)
  }

  const handlePopupOpen = (
    src,
    largeSrc,
    caption,
    afterSrc,
    afterLargeSrc,
    id,
    description,
  ) => {
    setIsPopupOpen(true)
    setPopupImageData({
      src,
      largeSrc,
      caption,
      afterSrc,
      afterLargeSrc,
      id,
      description,
    })
  }

  const handleShareToActiveChange = (
    src,
    largeSrc,
    caption,
    afterSrc,
    afterLargeSrc,
    id,
    description,
  ) => {
    setIsPopupOpen(true)
    setPopupImageData({
      src,
      largeSrc,
      caption,
      afterSrc,
      afterLargeSrc,
      id,
      description,
    })
  }

  const renderTagsOnImage = tagIds => {
    return (
      <div className="TagOnImageWrapper">
        {tagIds.map(tag => (
          <span key={tag} className="TagOnImage">
            {tagsData[tag].value}
          </span>
        ))}
      </div>
    )
  }

  // const renderShareButtons = (src, id, caption, description, hash) => {
  //   if (!isBrowser) {
  //     return null
  //   }

  //   const { meta = {} } = pageContext
  //   const { hash: pageHash = "" } = meta
  //   const { origin, pathname } = window
  //   const url = `${origin}${
  //     pageHash ? pathname.replace(`${pageHash}/`, "") : pathname
  //   }${hash}/${SHARE_ID}${id}`
  //   const documentTitle = isBrowser ? document.title : ""
  //   const shareCaption = caption && caption.trim() ? caption : documentTitle

  //   return (
  //     <div className="ShareButtonsWrapper">
  //       <p className="ShareTitle">{t("likePinterestBlock.share")}</p>
  //       <div className="ShareButtons">
  //         <ShareButton type="facebook" url={url} />
  //         <ShareButton type="twitter" url={url} twitterTitle={shareCaption} />
  //         <ShareButton
  //           type="pinterest"
  //           url={url}
  //           media={src}
  //           description={description}
  //         />
  //         <ShareButton
  //           type="email"
  //           url={url}
  //           media={src}
  //           subject={shareCaption}
  //           body={description}
  //         />
  //       </div>
  //     </div>
  //   )
  // }

  const renderImageBlock = (rowIndex, columnIndex) => {
    const childIndex = rowIndex + getRowCount() * columnIndex

    if (imagesDatas.length < childIndex + 1) return null

    const {
      [childIndex]: {
        afterSrc,
        afterLargeSrc,
        largeSrc,
        src,
        caption,
        tagIds,
        description,
      },
    } = imagesDatas

    const CaptionLink = caption?.props?.children[0]?.props

    if (
      activeTagIndex !== null &&
      !tagIds.find(tagId => tagId === activeTagIndex)
    )
      return null

    return (
      <div
        className={
          description ? "ImageBlockWrapper commercial-sec" : "ImageBlockWrapper"
        }
        key={childIndex}
      >
        <ShareWrapper
          isActive={isPopupOpen && childIndex === popupImageData.id}
          itemId={childIndex}
          shareId={SHARE_ID}
          onIsActive={() =>
            handleShareToActiveChange(
              src,
              largeSrc,
              caption,
              afterSrc,
              afterLargeSrc,
              childIndex,
              description,
            )
          }
          onIsNotActive={handleShareToNotActiveChange}
          metaTitle={caption}
          metaImageSrc={afterLargeSrc || largeSrc}
          metaDescription={description}
        >
          <div
            className="ImageBlockButtonWrapper"
            id={`${SHARE_ID.replace("#", "")}${childIndex}`}
          >
            <button
              className="ImageBlockButton"
              onClick={() =>
                handlePopupOpen(
                  src,
                  largeSrc,
                  caption,
                  afterSrc,
                  afterLargeSrc,
                  childIndex,
                  description,
                )
              }
            >
              <div className="ImageWrapper">
                <ProgressiveImage
                  src={src}
                  largeSrc={largeSrc}
                  alt="Board"
                  className="Image"
                />
                <div className="ImageLayer" />
                {renderTagsOnImage(tagIds)}
              </div>
            </button>
          </div>
        </ShareWrapper>
        {caption && description && (
          <h3 className="ImageBlockCaptionTitle">{caption}</h3>
        )}
        {description && <p className="ImageBlockCaption">{description}</p>}
        {CaptionLink && <a href={CaptionLink.href}>Learn more</a>}
      </div>
    )
  }

  const renderRow = rowIndex => {
    const imageCount = Math.ceil(imagesData.length / getRowCount())
    const imageMap = [...new Array(imageCount)]

    return (
      <div key={rowIndex} className="RowWrapper">
        {imageMap.map((_, columnIndex) =>
          renderImageBlock(rowIndex, columnIndex),
        )}
      </div>
    )
  }

  const renderTagButton = ({ value, id }) => {
    return (
      <button
        key={id}
        className={`TagButton ${activeTagIndex === id ? "isActive" : ""}`}
        data-label={value}
        onClick={() => {
          const imagesDataFiltered =
            id === null
              ? imagesData
              : imagesData.filter(({ tagIds }) =>
                  tagIds.find(tagId => tagId === id),
                )

          setActiveTagIndex(id)
          setImagesData(imagesDataFiltered)
        }}
      >
        <span className="TagButtonLabel">{value}</span>
      </button>
    )
  }

  const renderTagsSelector = () => {
    const us_es = pathname?.includes("us-es")

    return (
      <div className="TagSelectorWrapper">
        {renderTagButton({ value: t("likePinterestBlock.all"), id: null })}
        {us_es
          ? Object.values(tagsData)
              ?.sort(function (a, b) {
                const textA = a.value.toUpperCase()
                const textB = b.value.toUpperCase()

                return textA < textB ? -1 : textA > textB ? 1 : 0
              })
              ?.map(tag => renderTagButton(tag))
          : Object.values(tagsData).map(tag => renderTagButton(tag))}
      </div>
    )
  }

  const renderPopupBeforeAfter = () => {
    return (
      <BeforeAndAfterPicture
        beforeImageSrc={popupImageData.src}
        beforeImageLargeSrc={popupImageData.largeSrc}
        afterImageSrc={popupImageData.afterSrc}
        afterImageLargeSrc={popupImageData.afterLargeSrc}
        beforeLabelValue={t("likePinterestBlock.before")}
        afterLabelValue={t("likePinterestBlock.after")}
        sliderWrapperPadding={50}
      />
    )
  }

  const renderPopup = () => {
    return (
      <ModalPopup
        isVisible={isPopupOpen}
        handleClose={handlePopupClose}
        className="TagsBlock TagsModalPopup"
      >
        <div
          className={`ImageWrapper ${
            popupImageData.afterSrc ? "isBeforeAfter" : ""
          }`}
        >
          {popupImageData.afterSrc ? (
            renderPopupBeforeAfter()
          ) : (
            <ProgressiveImage
              src={popupImageData.src}
              largeSrc={popupImageData.largeSrc}
              alt="Board"
              className="Image"
            />
          )}
        </div>
        {popupImageData.caption && (
          <p className="Caption">
            {popupImageData.caption} <span>{popupImageData.description}</span>
          </p>
        )}
      </ModalPopup>
    )
  }

  const render = () => {
    const rowMap = [...new Array(getRowCount())]

    return (
      <BlockTransition elemsTransition={elemsTransition}>
        <div className="TagsBlock">
          {renderTagsSelector()}
          <div className="ContentWrapper">
            {rowMap.map((_, index) => renderRow(index))}
          </div>
        </div>
        {renderPopup()}
      </BlockTransition>
    )
  }

  return render()
}

export default withTranslation()(TagsBlock)
