import React, { useEffect, useState } from "react"
import { isBrowser } from "Utils/Helpers"

const BathroomSolutions = ({ children, uuid }) => {
  const [imageIds, setImageIds] = useState([])

  useEffect(() => {
    const setActiveImage = tile => {
      const linkingAttributeName = "data-image-id"
      const attributeValue = tile
        ? tile.getAttribute(linkingAttributeName)
        : null
      const allImageSelector = `.Image[${linkingAttributeName}]`
      const imageSelector = attributeValue
        ? `.Image[${linkingAttributeName}=${attributeValue}]`
        : allImageSelector
      const images = getElement(allImageSelector)

      images.forEach(image => {
        if (attributeValue) image.classList.remove("active")
      })

      const image = getElement(imageSelector)[0]
      if (!!image) image.classList.add("active")
    }

    const getElement = selector => {
      if (uuid === "") return []

      return document.querySelectorAll(
        `.ContentWrapper[data-uuid='${uuid}'] ${selector}`,
      )
    }

    const attachListener = () => {
      if (!isBrowser) return

      const tiles = getElement(".Tile")
      const firstTile = getElement(".Tile")[0]

      if (!tiles?.length || !firstTile) return

      setActiveImage(firstTile)

      tiles.forEach((tile, i) => {
        tile.addEventListener("click", e => {
          if (!tile.getAttribute("href")) e.preventDefault()
          setActiveImage(tile)
        })

        tile.addEventListener("mouseenter", () => setActiveImage(tile))

        const parentLink = tile.closest("a")
        if (parentLink && imageIds[i]) {
          parentLink.setAttribute("data-image-id", imageIds[i].imageId)
        }
      })
    }

    attachListener()

    return () => {
      const tiles = getElement(".Tile")
      tiles.forEach(tile => {
        tile.removeEventListener("click", () => {})
        tile.removeEventListener("mouseenter", () => {})
      })
    }
  }, [uuid, imageIds])

  useEffect(() => {
    const images = document.querySelectorAll(".Image")
    const newImageIds = []

    images.forEach((image, i) => {
      const imageId = image.getAttribute("data-image-id")

      if (imageId !== null) newImageIds.push({ i, imageId })
    })

    setImageIds(newImageIds)
  }, [children])

  if (!isBrowser) return null

  return (
    <div className="ContentWrapper" data-uuid={uuid}>
      {children}
    </div>
  )
}

export default BathroomSolutions
